import {
  generateAsymmetricKeys,
  encryptAsymmetric,
  decryptAsymmetric,
} from '@uniqkey-frontend/shared-app';
import {
  PartnerKeysApi,
  GetPartnerKeysResponse,
  SharePrivateKeysRequest,
  SharePrivateKeysResponse,
} from '@uniqkey-backend-partner/api-client';
import { IPartnerUserKeys } from '../partnerUserKeys';
import config from '../../../config';
import { axiosInstance } from '../../../axios';
import { logException } from '../../sentryService';
import { dataExtractor } from '../../../helpers/apiClients';

const partnerKeysClient = new PartnerKeysApi(
  undefined,
  config.getPartnerApiUrl(),
  axiosInstance,
);

interface IPartnerKeys {
  privateKey: string | null;
  publicKey: string;
}

const createPartnerKeys = async (
  partnerUserKeys: IPartnerUserKeys,
): Promise<IPartnerKeys | null> => {
  try {
    const { publicKey: partnerUserPublicKey } = partnerUserKeys;

    const {
      privateKey: generatedPrivateKey,
      publicKey: generatedPublicKey,
    } = await generateAsymmetricKeys();

    const encryptedPrivateKey = await encryptAsymmetric({
      string: generatedPrivateKey,
      publicKey: partnerUserPublicKey,
    });

    await partnerKeysClient.apiV1PartnerKeysPut({
      privatePartnerKey: encryptedPrivateKey,
      publicPartnerKey: generatedPublicKey,
    });

    return {
      privateKey: generatedPrivateKey,
      publicKey: generatedPublicKey,
    };
  } catch (e) {
    logException(e, { message: 'keysManager/createPartnerKeys failed to create partner keys' });
    return null;
  }
};

export const fetchPartnerKeys = async (
  partnerUserKeys: IPartnerUserKeys,
): Promise<Required<GetPartnerKeysResponse> | null> => {
  try {
    const {
      privateKey,
      publicKey,
      partnerKeyId,
    } = await partnerKeysClient
      .apiV1PartnerKeysGet()
      .then(dataExtractor<GetPartnerKeysResponse>());

    if (!privateKey) { // current admin has no access to the partner keys
      return {
        privateKey: null,
        publicKey,
        partnerKeyId,
      };
    }

    const { privateKey: partnerUserPrivateKey, publicKey: partnerUserPublicKey } = partnerUserKeys;

    const decryptedPrivateKey = await decryptAsymmetric({
      cipher: privateKey,
      privateKey: partnerUserPrivateKey,
      publicKey: partnerUserPublicKey,
    });

    return {
      privateKey: decryptedPrivateKey,
      publicKey,
      partnerKeyId,
    };
  } catch (e) {
    logException(e, { message: 'keysManager/fetchPartnerKeys failed to fetch partner keys' });
    return null;
  }
};

/**
 * @returns {string} privateKey - the current user has access to partner keys
 * @returns {null} privateKey - the current user doesn't have access to partner keys
 * */
export const setupPartnerKeys = async (
  partnerUserKeys: IPartnerUserKeys,
) : Promise<IPartnerKeys | null> => {
  const fetchedPartnerKeys = await fetchPartnerKeys(partnerUserKeys);

  if (fetchedPartnerKeys) {
    return fetchedPartnerKeys;
  }

  return createPartnerKeys(partnerUserKeys);
};

export const sharePartnerPrivateKey = async (
  sharePrivateKeysRequest: SharePrivateKeysRequest,
): Promise<SharePrivateKeysResponse> => {
  try {
    const { successCount, failCount } = await partnerKeysClient
      .apiV1PartnerKeysSharePrivateKeysPut(sharePrivateKeysRequest)
      .then(dataExtractor<SharePrivateKeysResponse>());
    return {
      successCount,
      failCount,
    };
  } catch (e) {
    logException(e, { message: 'keysManager/sharePartnerPrivateKey failed to share partner keys' });
    throw e;
  }
};
