// TODO: NEXTGEN-6419
import { memo/* , useCallback */ } from 'react';
import {
  PanelContent,
  // TODO: NEXTGEN-6419
  /* Divider,
  ExportButton,
  Grid,
  Tooltip,
  usePopper,
  useSnackbar,
  buildAndDownloadCSV,
  useTranslations, */
} from '@uniqkey-frontend/shared-app';
// TODO: NEXTGEN-6419
// import { OrganizationLicenseResponseData } from '@uniqkey-backend-partner/api-client';
import useOrganizationLicensesHistoryTable
  from '../../../../hooks/tables/useOrganizationLicensesHistoryTable';
import OrganizationLicensesHistoryTable
  from '../../../../components/tables/OrganizationLicensesHistoryTable';
// TODO: NEXTGEN-6419
/* import usePartnerOrganizationsAPI from '../../../../hooks/usePartnerOrganizationsAPI';
import { logException } from '../../../../services/sentryService';
import OrganizationExportLicensePopper from './components/OrganizationExportLicensePopper'; */

interface IOrganizationLicensesHistoryTab {
  organizationId: string;
}

const OrganizationLicensesHistoryTab = (props: IOrganizationLicensesHistoryTab) => {
  const { organizationId } = props;
  // TODO: NEXTGEN-6419
  /* const { t } = useTranslations();
  const { showError, showSuccess } = useSnackbar(); */

  const {
    ...restTableProps
  } = useOrganizationLicensesHistoryTable({
    persistentFilters: { organizationId },
    noDataMessageKey: 'organizationPage.licensesHistoryTab.noData',
  });

  // TODO: NEXTGEN-6419
  /* const {
    isOpen: isExportLicensePopperOpen,
    anchorEl: exportLicensePopperAnchorEl,
    setPopperIsOpen: setIsExportLicensePopperOpen,
  } = usePopper();
  const toggleIsExportLicensePopperOpen = useCallback(
    (event) => {
      setIsExportLicensePopperOpen(!isExportLicensePopperOpen, event);
    },
    [setIsExportLicensePopperOpen, isExportLicensePopperOpen],
  );

  const handleExportLicensePopperClose = useCallback(
    () => setIsExportLicensePopperOpen(false),
    [setIsExportLicensePopperOpen],
  );

  const { getOrganizationExportLicenseData } = usePartnerOrganizationsAPI();

  const handleExportLicensePopperSubmit = useCallback(async (updatedValues) => {
    try {
      const { data: exportLicenseData } = await getOrganizationExportLicenseData(
        organizationId,
        updatedValues.exportPeriod,
      );
      buildAndDownloadCSV<OrganizationLicenseResponseData>({
        exportData: exportLicenseData,
        name: 'exported_organization_licenses',
      });
      showSuccess({ text: t('organizationPage.licenseHistoryTab.exportSuccessMessage') });
    } catch (e) {
      showError({ text: t('common.somethingWentWrong') });
      logException(e, {
        message: 'OrganizationLicensesHistoryTab/handleExportLicensePopperSubmit exception',
      });
    }
  }, [getOrganizationExportLicenseData, organizationId, showError, showSuccess, t]); */

  return (
    <PanelContent p={0}>
      {/* TODO: NEXTGEN-6419 */}
      {/* <Grid container p={1}>
        <Tooltip title={t('organizationExportLicensePopper.tooltip')}>
          <ExportButton
            selected={isExportLicensePopperOpen}
            onChange={toggleIsExportLicensePopperOpen}
          />
        </Tooltip>
      </Grid>
      <Divider /> */}
      <OrganizationLicensesHistoryTable
        {...restTableProps}
      />
      {/* TODO: NEXTGEN-6419 */}
      {/* <OrganizationExportLicensePopper
        isOpen={isExportLicensePopperOpen}
        anchorEl={exportLicensePopperAnchorEl}
        onSubmit={handleExportLicensePopperSubmit}
        onClose={handleExportLicensePopperClose}
      /> */}
    </PanelContent>
  );
};

export default memo(OrganizationLicensesHistoryTab);
