import { memo, useCallback, useState } from 'react';
import {
  PanelContent,
  Grid,
  Divider,
  Button,
  useTranslations,
  InviteUserIcon,
  Tooltip,
  ActionButton,
  DeleteIcon,
  useMapKeyValueExtractor,
  useSnackbar,
  PromptModal,
} from '@uniqkey-frontend/shared-app';
import useGroupUsersTable from '../../../../hooks/tables/useGroupUsersTable';
import GroupUsersTable, {
  IGroupUsersTableRow,
} from '../../../../components/tables/GroupUsersTable';
import UsersSelectorModal from '../UsersSelectorModal';
import { useRemoveUsersFromGroup } from '../../../../hooks/reactQuery';
import { logException } from '../../../../services/sentryService';

interface IGroupUsersTabProps {
  groupId: string;
}

const GroupUsersTab = (props: IGroupUsersTabProps) => {
  const { groupId } = props;
  const { t } = useTranslations();
  const { showError, showSuccess } = useSnackbar();

  const [isUsersSelectorModalOpen, setIsUsersSelectorModalOpen] = useState(false);
  const [isRemoveUsersFromGroupModalOpen, setIsRemoveUsersFromGroupModalOpen] = useState(false);

  const handleUsersSelectorModalOpen = useCallback(
    () => setIsUsersSelectorModalOpen(true),
    [],
  );
  const handleUsersSelectorModalClose = useCallback(
    () => setIsUsersSelectorModalOpen(false),
    [],
  );

  const handleRemoveUsersFromGroupModalOpen = useCallback(
    () => setIsRemoveUsersFromGroupModalOpen(true),
    [],
  );

  const handleRemoveUsersFromGroupModalClose = useCallback(
    () => setIsRemoveUsersFromGroupModalOpen(false),
    [],
  );

  const {
    selectedGroupUsers,
    resetSelectedRows,
    resetActivePage,
    refetch,
    ...restTableProps
  } = useGroupUsersTable({
    persistentFilters: { groupId },
    noDataMessageKey: 'table.noData.default',
  });

  const {
    mutate: mutateRemoveUsersFromGroup,
    isLoading: isRemoveUsersFromGroupLoading,
  } = useRemoveUsersFromGroup();

  const {
    values: selectedUsersAsObjects, keys: selectedUsersIds,
  } = useMapKeyValueExtractor<IGroupUsersTableRow>(selectedGroupUsers);

  const handleRemoveUsersFromGroup = useCallback(async () => {
    mutateRemoveUsersFromGroup(
      { groupId, partnerUserIds: selectedUsersIds },
      {
        onSuccess: ({ failCount, successCount }) => {
          if (successCount) {
            showSuccess({
              text: t(
                'removeUsersFromGroupModal.successMessage',
                { count: successCount },
              ),
            });
          }
          if (failCount) {
            showError({ text: t('removeUsersFromGroupModal.errorMessage', { count: failCount }) });
          }
          handleRemoveUsersFromGroupModalClose();
          resetSelectedRows();
          resetActivePage();
        },
        onError: (e) => {
          showError({ text: t('common.somethingWentWrong') });
          logException(e, { message: 'GroupUsersTab/handleRemoveUsersFromGroup exception' });
        },
        onSettled: () => {
          refetch();
        },
      },
    );
  }, [
    mutateRemoveUsersFromGroup,
    handleRemoveUsersFromGroupModalClose,
    groupId,
    selectedUsersIds,
    resetSelectedRows,
    resetActivePage,
    refetch,
    showError,
    showSuccess,
    t,
  ]);

  return (
    <PanelContent p={0}>
      <Grid item container justifyContent="flex-end" flexWrap="nowrap" p={1}>
        <Grid item xs={4} container flexWrap="nowrap" spacing={1}>
          {!!selectedGroupUsers.size && (
          <>
            <Grid item my={0.5}>
              <Divider orientation="vertical" />
            </Grid>
            <Grid item alignSelf="center">
              <Tooltip title={t('common.remove')}>
                <ActionButton
                  width={40}
                  height={40}
                  onClick={handleRemoveUsersFromGroupModalOpen}
                >
                  <DeleteIcon />
                </ActionButton>
              </Tooltip>
            </Grid>
            <Grid item my={0.5}>
              <Divider orientation="vertical" />
            </Grid>
          </>
          )}
        </Grid>
        <Grid item xs={8} container justifyContent="flex-end" flexWrap="nowrap">
          <Button
            icon={<InviteUserIcon />}
            onClick={handleUsersSelectorModalOpen}
          >
            {t('groupPage.usersTab.addUserButton')}
          </Button>
        </Grid>
      </Grid>
      <Divider />
      <GroupUsersTable
        selectedGroupUsers={selectedGroupUsers}
        {...restTableProps}
      />
      {isUsersSelectorModalOpen && (
        <UsersSelectorModal
          isOpen={isUsersSelectorModalOpen}
          onClose={handleUsersSelectorModalClose}
          groupId={groupId}
          refetch={refetch}
        />
      )}
      {isRemoveUsersFromGroupModalOpen && (
        <PromptModal
          open={isRemoveUsersFromGroupModalOpen}
          onClose={handleRemoveUsersFromGroupModalClose}
          onSubmit={handleRemoveUsersFromGroup}
          title={t(
            'removeUsersFromGroupModal.title',
            { count: selectedGroupUsers.size },
          )}
          description={t('removeUsersFromGroupModal.description')}
          approvalButtonText="common.remove"
          list={selectedUsersAsObjects}
          renderField="name"
          renderKey="partnerUserId"
          isLoading={isRemoveUsersFromGroupLoading}
        />
      )}
    </PanelContent>
  );
};

export default memo(GroupUsersTab);
