import {
  TUseQueryOptions,
  TUseMutationOptions,
  TUseMutationContext,
} from '@uniqkey-frontend/shared-app';
import {
  GroupByIdResponse,
  NoContentResult,
  UpdateGroupRequest,
  GroupUsersResponse,
  RemoveUsersFromGroupRequest,
  RemoveUsersFromGroupResponse,
  GroupOrganizationsResponse,
  RemoveOrganizationsFromGroupResponse,
  RemoveOrganizationsFromGroupRequest,
} from '@uniqkey-backend-partner/api-client';
import { useQuery, useQueryClient, useMutation } from 'react-query';
import ReactQueryKeyEnum from '../../../enums/ReactQueryKeyEnum';
import useGroupsAPI from '../../useGroupsAPI';
import { IGetGroupOrganizationsParams, IGetGroupUsersParams } from '../../useGroupsAPI/interfaces';

interface IUseGetGroupByIdParams {
  groupId: string;
}

export const useGetGroupById = (
  params: IUseGetGroupByIdParams,
  options: TUseQueryOptions<GroupByIdResponse> = {},
) => {
  const { groupId } = params;
  const { getGroupById } = useGroupsAPI();
  return useQuery<GroupByIdResponse>(
    [ReactQueryKeyEnum.Group, groupId],
    ({ signal }) => getGroupById(groupId, { signal }),
    { notifyOnChangeProps: 'tracked', ...options },
  );
};

interface IUseUpdateGroupParams {
  groupId: string;
  useOptimisticUpdates?: boolean;
}
export const useUpdateGroup = (
  params: IUseUpdateGroupParams,
  options: TUseMutationOptions<
    NoContentResult,
    unknown,
    UpdateGroupRequest,
    TUseMutationContext<GroupByIdResponse>
  > = {},
) => {
  const { groupId, useOptimisticUpdates = false } = params;
  const queryClient = useQueryClient();
  const { updateGroup } = useGroupsAPI();
  const mutationKey = [ReactQueryKeyEnum.Group, groupId];
  return useMutation(
    mutationKey,
    (group) => updateGroup(
      { ...group, groupId },
    ),
    {
      onMutate: async (newGroup) => {
        if (!useOptimisticUpdates) {
          return null;
        }
        await queryClient.cancelQueries(mutationKey);
        const previousValue = queryClient.getQueryData<
          GroupByIdResponse
        >(mutationKey);
        queryClient.setQueryData<GroupByIdResponse>(
          mutationKey,
          (oldGroup) => ({
            ...oldGroup,
            ...newGroup as GroupByIdResponse,
          }),
        );
        return { previousValue: previousValue as GroupByIdResponse };
      },
      onError: (err, group, context) => {
        if (context?.previousValue) {
          queryClient.setQueryData<GroupByIdResponse>(
            mutationKey,
            context.previousValue,
          );
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries(mutationKey);
      },
      ...options,
    },
  );
};

export const useGetGroupUsers = (
  params: IGetGroupUsersParams,
  options: TUseQueryOptions<GroupUsersResponse> = {},
) => {
  const { getGroupUsers } = useGroupsAPI();
  return useQuery<GroupUsersResponse>(
    [ReactQueryKeyEnum.GroupUsers],
    ({ signal }) => getGroupUsers({ signal, ...params }),
    { notifyOnChangeProps: 'tracked', ...options },
  );
};

export const useRemoveUsersFromGroup = (
  options: TUseMutationOptions<
    RemoveUsersFromGroupResponse,
    unknown,
    RemoveUsersFromGroupRequest,
    void
  > = {},
) => {
  const queryClient = useQueryClient();
  const { removeUsersFromGroup } = useGroupsAPI();
  return useMutation((
    removeUsersFromGroupRequest,
  ) => removeUsersFromGroup(removeUsersFromGroupRequest), {
    onSettled: (data, error, variables) => {
      variables.partnerUserIds.forEach((partnerUserId) => {
        queryClient.removeQueries([ReactQueryKeyEnum.GroupUsers, partnerUserId], { exact: true });
      });
    },
    ...options,
  });
};

export const useGetGroupOrganizations = (
  params: IGetGroupOrganizationsParams,
  options: TUseQueryOptions<GroupOrganizationsResponse> = {},
) => {
  const { getGroupOrganizations } = useGroupsAPI();
  return useQuery<GroupOrganizationsResponse>(
    [ReactQueryKeyEnum.GroupOrganizations],
    ({ signal }) => getGroupOrganizations({ signal, ...params }),
    { notifyOnChangeProps: 'tracked', ...options },
  );
};

export const useRemoveOrganizationsFromGroup = (
  options: TUseMutationOptions<
    RemoveOrganizationsFromGroupResponse,
    unknown,
    RemoveOrganizationsFromGroupRequest,
    void
  > = {},
) => {
  const queryClient = useQueryClient();
  const { removeOrganizationsFromGroup } = useGroupsAPI();
  return useMutation((
    removeOrganizationsFromGroupRequest,
  ) => removeOrganizationsFromGroup(removeOrganizationsFromGroupRequest), {
    onSettled: (data, error, variables) => {
      variables.organizationIds.forEach((organizationId) => {
        queryClient.removeQueries(
          [ReactQueryKeyEnum.GroupOrganizations, organizationId],
          { exact: true },
        );
      });
    },
    ...options,
  });
};
