enum ReactQueryKeyEnum {
  Partner = 'partner',
  CurrentUser = 'currentUser',
  Organization = 'organization',
  OrganizationAccessInfo = 'organizationAccessInfo',
  OrganizationEmployee = 'organizationEmployee',
  ContactPerson = 'contactPerson',
  PartnerUser = 'partnerUser',
  Group = 'group',
  GroupUsers = 'groupUsers',
  GroupOrganizations = 'groupOrganizations',
  ACLs = 'ACLs'
}

export default ReactQueryKeyEnum;
