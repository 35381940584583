import { useMemo, memo, useCallback } from 'react';
import {
  Table,
  TypographyWithTooltip,
  useTranslations,
  IUseTableReturn,
  IUseTableFetchReturn,
  IUseTableLocalizationReturn,
  IMaterialTableOptions,
  IMaterialTableProps,
} from '@uniqkey-frontend/shared-app';
import {
  GroupOrganizationsResponseModel,
} from '@uniqkey-backend-partner/api-client';
import { useNavigate } from 'react-router-dom';
import PageRouteEnum from '../../../enums/PageRouteEnum';

export interface IGroupOrganizationsTableRow extends GroupOrganizationsResponseModel {}

interface IGroupOrganizationsTableProps extends
  Omit<IUseTableReturn<IGroupOrganizationsTableRow>, 'selectedRows' | 'resetTableMethods'>,
  Omit<IUseTableFetchReturn<GroupOrganizationsResponseModel>, 'data' | 'refetch'>
{
  groupOrganizations: IUseTableFetchReturn<GroupOrganizationsResponseModel>['data'];
  selectedGroupOrganizations: IUseTableReturn<IGroupOrganizationsTableRow>['selectedRows'];
  localization: IUseTableLocalizationReturn['localization'];
  groupId: string;
}

const TABLE_OPTIONS: IMaterialTableOptions<IGroupOrganizationsTableRow> = {
  selection: true,
};

const GroupOrganizationsTable = (props: IGroupOrganizationsTableProps) => {
  const {
    groupOrganizations,
    isLoading,
    total,
    localization,
    activePage,
    setActivePage,
    perPage,
    onPerPageChange,
    columnOrderBy,
    onColumnOrderByChange,
    columnOrderDirection,
    onColumnOrderDirectionChange,
    selectedGroupOrganizations,
    onRowSelectionChange,
    createColumns,
    groupId,
  } = props;

  const { t } = useTranslations();
  const navigate = useNavigate();

  const columns = useMemo(() => createColumns([
    {
      title: t('groupPage.name'),
      field: 'name',
      render: (rowData) => (
        <TypographyWithTooltip variant="body2">
          {rowData.name}
        </TypographyWithTooltip>
      ),
      width: '85%',
    },
    {
      title: t('groupPage.vat'),
      field: 'vat',
      render: (rowData) => (
        <TypographyWithTooltip variant="body2">
          {rowData.vat}
        </TypographyWithTooltip>
      ),
      width: '15%',
    },
  ]), [createColumns, t]);

  const handleGroupOrganizationRowClick = useCallback<
    NonNullable<IMaterialTableProps<IGroupOrganizationsTableRow>['onRowClick']>
  >((event, organization) => {
    const { organizationId } = organization!;
    navigate(`${PageRouteEnum.Groups}/${groupId}/${organizationId}`);
  }, [groupId, navigate]);

  return (
    <Table
      t={t}
      totalItems={total}
      columns={columns}
      data={groupOrganizations}
      isLoading={isLoading}
      options={TABLE_OPTIONS}
      activePage={activePage}
      onActivePageChange={setActivePage}
      perPage={perPage}
      onPerPageChange={onPerPageChange}
      columnOrderBy={columnOrderBy}
      onColumnOrderByChange={onColumnOrderByChange}
      columnOrderDirection={columnOrderDirection}
      onColumnOrderDirectionChange={onColumnOrderDirectionChange}
      selectedRows={selectedGroupOrganizations}
      onSelectionChange={onRowSelectionChange}
      localization={localization}
      onRowClick={handleGroupOrganizationRowClick}
      rowSelectByKey="organizationId"
      rowHeight={51}
    />
  );
};

export default memo(GroupOrganizationsTable);
