import { useMemo, memo } from 'react';
import {
  Table,
  TypographyWithTooltip,
  useTranslations,
  IUseTableReturn,
  IUseTableFetchReturn,
  IUseTableLocalizationReturn,
  IMaterialTableOptions,
} from '@uniqkey-frontend/shared-app';
import {
  GroupUsersResponseModel,
} from '@uniqkey-backend-partner/api-client';

export interface IGroupUsersTableRow extends GroupUsersResponseModel {}

interface IGroupUsersTableProps extends
  Omit<IUseTableReturn<IGroupUsersTableRow>, 'selectedRows' | 'resetTableMethods'>,
  Omit<IUseTableFetchReturn<GroupUsersResponseModel>, 'data' | 'refetch'>
{
  groupUsers: IUseTableFetchReturn<GroupUsersResponseModel>['data'];
  selectedGroupUsers: IUseTableReturn<IGroupUsersTableRow>['selectedRows'];
  localization: IUseTableLocalizationReturn['localization'];
}

const TABLE_OPTIONS: IMaterialTableOptions<IGroupUsersTableRow> = {
  selection: true,
};

const GroupUsersTable = (props: IGroupUsersTableProps) => {
  const {
    groupUsers,
    isLoading,
    total,
    localization,
    activePage,
    setActivePage,
    perPage,
    onPerPageChange,
    columnOrderBy,
    onColumnOrderByChange,
    columnOrderDirection,
    onColumnOrderDirectionChange,
    selectedGroupUsers,
    onRowSelectionChange,
    createColumns,
  } = props;

  const { t } = useTranslations();

  const columns = useMemo(() => createColumns([
    {
      title: t('groupPage.name'),
      field: 'name',
      render: (rowData) => (
        <TypographyWithTooltip variant="body2">
          {rowData.name}
        </TypographyWithTooltip>
      ),
      width: '50%',
    },
    {
      title: t('groupPage.email'),
      field: 'email',
      render: (rowData) => (
        <TypographyWithTooltip variant="body2">
          {rowData.email}
        </TypographyWithTooltip>
      ),
      width: '50%',
    },
  ]), [createColumns, t]);

  return (
    <Table
      t={t}
      totalItems={total}
      columns={columns}
      data={groupUsers}
      isLoading={isLoading}
      options={TABLE_OPTIONS}
      activePage={activePage}
      onActivePageChange={setActivePage}
      perPage={perPage}
      onPerPageChange={onPerPageChange}
      columnOrderBy={columnOrderBy}
      onColumnOrderByChange={onColumnOrderByChange}
      columnOrderDirection={columnOrderDirection}
      onColumnOrderDirectionChange={onColumnOrderDirectionChange}
      selectedRows={selectedGroupUsers}
      onSelectionChange={onRowSelectionChange}
      localization={localization}
      rowSelectByKey="partnerUserId"
      rowHeight={51}
    />
  );
};

export default memo(GroupUsersTable);
