import { memo, useCallback, useState } from 'react';
import {
  Button,
  Grid,
  Typography,
  WidgetContainer,
  useSnackbar,
  useTranslations,
  List,
  RenderIf,
} from '@uniqkey-frontend/shared-app';
import { useNavigate } from 'react-router-dom';
import { useGetGroupUsers } from '../../../../../../hooks/reactQuery';
import AddUserToGroupWidgetListItem from './components/AddUserToGroupWidgetListItem';
import PageRouteEnum from '../../../../../../enums/PageRouteEnum';
import { GroupModuleTabEnum } from '../../../GroupModule';
import AddUserToGroupWidgetNoData from './components/AddUserToGroupWidgetNoData';
import UsersSelectorModal from '../../../UsersSelectorModal';

interface IAddUserToGroupWidgetProps {
  groupId: string;
}

const AddUserToGroupWidget = (props: IAddUserToGroupWidgetProps) => {
  const { groupId } = props;

  const { t } = useTranslations();
  const { showError } = useSnackbar();
  const navigate = useNavigate();

  const {
    data, isLoading, isError, refetch,
  } = useGetGroupUsers({
    groupId,
    page: 1,
    pageLength: 2,
  }, {
    onError: () => {
      showError({ text: t('common.somethingWentWrong') });
    },
  });

  const [isUsersSelectorModalOpen, setIsUsersSelectorModalOpen] = useState(false);
  const handleUsersSelectorModalOpen = useCallback(
    () => setIsUsersSelectorModalOpen(true),
    [],
  );
  const handleUsersSelectorModalClose = useCallback(
    () => setIsUsersSelectorModalOpen(false),
    [],
  );

  const handleNavigation = useCallback(() => {
    navigate({
      pathname: `${PageRouteEnum.Groups}/${groupId}/${GroupModuleTabEnum.Users}`,
    });
  }, [groupId, navigate]);

  const { data: preUsers } = data ?? {};
  const users = preUsers ?? [];

  if (isError) {
    return null;
  }

  if (!users.length && !isLoading) {
    return (
      <AddUserToGroupWidgetNoData
        refetch={refetch}
        groupId={groupId}
      />
    );
  }

  return (
    <WidgetContainer
      flexDirection="column"
      py={3}
      withShadow
      isLoading={isLoading}
    >
      <Grid item>
        <Typography variant="h6">{t('addUserToGroupWidget.title')}</Typography>
      </Grid>
      <List>
        {users.map((user) => (
          <AddUserToGroupWidgetListItem
            groupId={groupId}
            user={user}
            key={user.partnerUserId}
            refetch={refetch}
          />
        ))}
      </List>
      <Grid container item columnGap={1}>
        <Grid item mt={2}>
          <Button variant="outlined" fullWidth onClick={handleUsersSelectorModalOpen}>
            {t('addUserToGroupWidget.addUser')}
          </Button>
        </Grid>
        <RenderIf condition={!!users.length}>
          <Grid item mt={2}>
            <Button variant="outlined" fullWidth onClick={handleNavigation}>
              {t('addUserToGroupWidget.viewAll')}
            </Button>
          </Grid>
        </RenderIf>
      </Grid>
      {isUsersSelectorModalOpen && (
        <UsersSelectorModal
          isOpen={isUsersSelectorModalOpen}
          onClose={handleUsersSelectorModalClose}
          groupId={groupId}
          refetch={refetch}
        />
      )}
    </WidgetContainer>
  );
};

export default memo(AddUserToGroupWidget);
