import {
  fetchPartnerUserKeys,
  setupPartnerUserKeys,
  fetchPartnerUsersPublicKeys,
} from './partnerUserKeys';
import { fetchPartnerKeys, setupPartnerKeys, sharePartnerPrivateKey } from './partnerKeys';

export default {
  fetchPartnerUserKeys,
  setupPartnerUserKeys,
  fetchPartnerUsersPublicKeys,
  fetchPartnerKeys,
  setupPartnerKeys,
  sharePartnerPrivateKey,
};
