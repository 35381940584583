/* eslint-disable max-len */
const config = {
  requestTimeout: parseInt(process.env.REACT_APP_REQUEST_TIMEOUT as string, 10) ?? 10000,
  sentryDSN: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_ENVIRONMENT,
  useLocalTranslations: process.env.REACT_APP_USE_LOCAL_TRANSLATIONS === 'true',
  getPartnerApiUrl: () => `https://partner-api.${process.env.REACT_APP_ENVIRONMENT}.next.uniqkey.eu`,
  getOPBaseUrl: () => process.env.REACT_APP_LOCAL_OP_BASE_URL || `https://app.${process.env.REACT_APP_ENVIRONMENT}.next.uniqkey.eu`,
  getSupportJumpUrl: (organizationId: string, token: string) => `${config.getOPBaseUrl()}/support/jump/${organizationId}/${token}`,
};

export default config;
