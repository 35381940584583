import {
  WidgetContainer,
  Button,
  VisualShieldIcon,
  useTranslations,
  useSnackbar,
  Tooltip,
  Grid,
  JumpToAdminPortalIcon,
} from '@uniqkey-frontend/shared-app';
import {
  PartnerAccessStatus,
  GetOrganizationPortalAccessInfoResponse,
} from '@uniqkey-backend-partner/api-client';
import { useCallback, useState, memo } from 'react';
import config from '../../../../../../config';
import { useUser } from '../../../../../../contexts/UserContext';
import ACLEnum from '../../../../../../enums/ACLEnum';
import { useGetOrganizationAccessInfo } from '../../../../../../hooks/reactQuery';
import usePartnerOrganizationsAPI from '../../../../../../hooks/usePartnerOrganizationsAPI';
import usePartnerUsersAPI from '../../../../../../hooks/usePartnerUsersAPI';
import { logException } from '../../../../../../services/sentryService';

interface IRequestAccessWidgetProps {
  organizationId: string;
}

const ACCESS_STATUS_REACT_QUERY_REFETCH_INTERVAL = 15 * 1000;

const TOOLTIP_TRANSLATIONS_KEYS = {
  [PartnerAccessStatus.NoAccess]: 'requestAccessWidget.jumpToAdminPortalButton.tooltip.noAccess',
  [PartnerAccessStatus.NoKeys]: 'requestAccessWidget.jumpToAdminPortalButton.tooltip.noKeys',
  [PartnerAccessStatus.PendingApproval]:
    'requestAccessWidget.jumpToAdminPortalButton.tooltip.pendingApproval',
  [PartnerAccessStatus.Rejected]: 'requestAccessWidget.jumpToAdminPortalButton.tooltip.rejected',
};

const LABEL_TRANSLATIONS_KEYS = {
  [PartnerAccessStatus.NoAccess]: 'requestAccessWidget.jumpToAdminPortalButton.label.noAccess',
  [PartnerAccessStatus.NoKeys]: 'requestAccessWidget.jumpToAdminPortalButton.label.noKeys',
  [PartnerAccessStatus.PendingApproval]:
    'requestAccessWidget.jumpToAdminPortalButton.label.pendingApproval',
  [PartnerAccessStatus.Rejected]: 'requestAccessWidget.jumpToAdminPortalButton.label.rejected',
  [PartnerAccessStatus.HasAccess]: 'requestAccessWidget.jumpToAdminPortalButton.label.hasAccess',
};

const RequestAccessWidget = (props: IRequestAccessWidgetProps) => {
  const { organizationId } = props;
  const { showSuccess, showError } = useSnackbar();
  const { t } = useTranslations();
  const { userCan } = useUser();

  const [isRequestOrganizationLoginLoading, setIsRequestOrganizationLoginLoading] = useState(false);

  const {
    data: organizationAccessInfo,
    isLoading: isOrganizationAccessInfoLoading,
    isError: isOrganizationAccessInfoError,
    refetch: refetchOrganizationAccessInfo,
  } = useGetOrganizationAccessInfo(
    { organizationId },
    {
      refetchInterval: ACCESS_STATUS_REACT_QUERY_REFETCH_INTERVAL,
    },
  );
  const { accessStatus } = organizationAccessInfo ?? {} as GetOrganizationPortalAccessInfoResponse;

  const { requestOrganizationLogin } = usePartnerOrganizationsAPI();
  const { getJumpToken } = usePartnerUsersAPI();

  const handleRequestOrganizationLogin = useCallback(async () => {
    try {
      setIsRequestOrganizationLoginLoading(true);
      await requestOrganizationLogin(organizationId);
      showSuccess({ text: t('requestAccessWidget.successMessage') });
    } catch (e) {
      showError({ text: t('common.somethingWentWrong') });
      logException(e, {
        message: 'RequestAccessWidget/handleRequestOrganizationLogin exception',
      });
    } finally {
      setIsRequestOrganizationLoginLoading(false);
      await refetchOrganizationAccessInfo();
    }
  }, [
    organizationId,
    refetchOrganizationAccessInfo,
    requestOrganizationLogin,
    showError,
    showSuccess,
    t,
  ]);

  const handleJumpToAdminPortal = useCallback(async () => {
    try {
      const { token } = await getJumpToken();
      const redirectUrl = config.getSupportJumpUrl(organizationId, token);
      window.open(redirectUrl, 'OPSupportTab');
    } catch (e) {
      showError({ text: t('common.somethingWentWrong') });
      logException(e, {
        message: 'RequestAccessWidget/handleJumpToAdminPortal exception',
      });
    }
  }, [getJumpToken, organizationId, showError, t]);

  const isRequestAccessButtonActive = accessStatus === PartnerAccessStatus.NoAccess
    || accessStatus === PartnerAccessStatus.Rejected;

  const isJumpToAdminPortalButtonActive = accessStatus === PartnerAccessStatus.HasAccess;

  if (isOrganizationAccessInfoError) {
    return null;
  }

  return (
    <WidgetContainer
      container
      paddingY={1}
      paddingX={2}
      justifyContent="space-between"
      withShadow
      isLoading={isOrganizationAccessInfoLoading}
    >
      {userCan(ACLEnum.RequestOrganizationLogin) && (
      <Grid minWidth={220}>
        <Button
          disabled={!isRequestAccessButtonActive}
          icon={<VisualShieldIcon />}
          onClick={handleRequestOrganizationLogin}
          isLoading={isRequestOrganizationLoginLoading}
          fullWidth
        >
          {t('requestAccessWidget.requestAccessButtonText')}
        </Button>
      </Grid>
      )}
      <Tooltip
        title={isJumpToAdminPortalButtonActive ? null
          : t(TOOLTIP_TRANSLATIONS_KEYS[accessStatus])}
      >
        <Grid minWidth={250}>
          <Button
            disabled={!isJumpToAdminPortalButtonActive}
            icon={<JumpToAdminPortalIcon />}
            onClick={handleJumpToAdminPortal}
            fullWidth
          >
            {t(LABEL_TRANSLATIONS_KEYS[accessStatus])}
          </Button>
        </Grid>
      </Tooltip>
    </WidgetContainer>
  );
};

export default memo(RequestAccessWidget);
