import { useCallback, memo, useState } from 'react';
import {
  useSnackbar,
  useTranslations,
} from '@uniqkey-frontend/shared-app';
import { GetOrganizationsResponseModel } from '@uniqkey-backend-partner/api-client';
import { logException } from '../../../../services/sentryService';
import usePartnerOrganizationsAPI from '../../../../hooks/usePartnerOrganizationsAPI';
import useGroupsAPI from '../../../../hooks/useGroupsAPI';
import EntitySelectorModal, {
  TEntitySelectorModalOnSubmit,
} from '../../../../components/EntitySelectorModal';

interface IOrganizationsSelectorModalProps {
  isOpen: boolean;
  onClose: () => void;
  groupId: string;
  onSubmit?: () => void;
  refetch: () => void;
}

interface IParsedEntity {
  id: GetOrganizationsResponseModel['organizationId'];
  label: string;
}

const OrganizationsSelectorModal = (props: IOrganizationsSelectorModalProps) => {
  const {
    isOpen, onClose, groupId, onSubmit, refetch,
  } = props;

  const { t } = useTranslations();
  const { showSuccess, showError } = useSnackbar();
  const { getOrganizations } = usePartnerOrganizationsAPI();
  const { addOrganizationsToGroup } = useGroupsAPI();

  const [
    isOrganizationsSelectorModalLoading,
    setIsOrganizationsSelectorModalLoading,
  ] = useState(false);

  const handleAutocompleteRequest = useCallback(async (searchQuery: string) => {
    try {
      const { data } = await getOrganizations({ page: 1, pageLength: 100, searchQuery });
      return data!; // TODO: remove '!' when data in interface will be not optional
    } catch (e) {
      showError({
        text: t('common.somethingWentWrong'),
      });
      logException(e, {
        message: 'OrganizationsSelectorModal/handleAutocompleteRequest exception',
      });
      return [];
    }
  }, [getOrganizations, showError, t]);

  const handleSubmitEntitySelectorModal = useCallback<
    TEntitySelectorModalOnSubmit<IParsedEntity[]>
  >(async (value) => {
    try {
      const organizationIds = value.map((el: { id: string }) => el.id);
      setIsOrganizationsSelectorModalLoading(true);
      await addOrganizationsToGroup({ organizationIds, groupId });
      showSuccess({
        text: t(
          'addOrganizationsToGroupModal.organizationAddedToGroup',
          { count: organizationIds.length },
        ),
      });
      if (onSubmit) {
        onSubmit();
      }
      refetch();
      onClose();
    } catch (e) {
      showError({
        text: t('common.somethingWentWrong'),
      });
      logException(e, {
        message: 'OrganizationsSelectorModal/handleSubmitEntitySelectorModal exception',
      });
    } finally {
      setIsOrganizationsSelectorModalLoading(false);
    }
  }, [refetch, addOrganizationsToGroup, groupId, onSubmit, onClose, showError, showSuccess, t]);

  const handleResponseEntitySelectorModal = useCallback(
    (organizationToParse: GetOrganizationsResponseModel[]) => organizationToParse.map(
      (organization: GetOrganizationsResponseModel) => ({
        id: organization.organizationId,
        label: `${organization.name}, ${organization.vat}`,
      }),
    ),
    [],
  );

  return (
    <EntitySelectorModal
      isOpen={isOpen}
      title={t('addOrganizationsToGroupModal.title')}
      placeholder={t('addOrganizationsToGroupModal.selectOrganizationsPlaceholder')}
      description={t('addOrganizationsToGroupModal.description')}
      onSubmit={handleSubmitEntitySelectorModal}
      request={handleAutocompleteRequest}
      responseParser={handleResponseEntitySelectorModal}
      onClose={onClose}
      isLoading={isOrganizationsSelectorModalLoading}
    />
  );
};

export default memo(OrganizationsSelectorModal);
