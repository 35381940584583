import { useCallback, useMemo, memo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Table,
  TypographyWithTooltip,
  useTranslations,
  IUseTableReturn,
  IUseTableFetchReturn,
  IUseTableLocalizationReturn,
  IMaterialTableOptions,
  IMaterialTableProps,
} from '@uniqkey-frontend/shared-app';
import {
  GetOrganizationsResponseModel,
} from '@uniqkey-backend-partner/api-client';
import PageRouteEnum from '../../../enums/PageRouteEnum';

export interface IOrganizationsTableRow extends GetOrganizationsResponseModel {}

interface IOrganizationsTableProps extends
  Omit<IUseTableReturn<IOrganizationsTableRow>, 'selectedRows' | 'resetTableMethods'>,
  Omit<IUseTableFetchReturn<GetOrganizationsResponseModel>, 'data' | 'refetch'>
{
  organizations: IUseTableFetchReturn<GetOrganizationsResponseModel>['data'];
  selectedOrganizations: IUseTableReturn<IOrganizationsTableRow>['selectedRows'];
  localization: IUseTableLocalizationReturn['localization'];
  partnerId?: string;
}

const TABLE_OPTIONS: IMaterialTableOptions<IOrganizationsTableRow> = {
  selection: true,
};

const COLUMN_TOOLTIP_PROPS = { cursorPointer: true };

const OrganizationsTable = (props: IOrganizationsTableProps) => {
  const {
    organizations,
    isLoading,
    total,
    localization,
    partnerId,
    activePage,
    setActivePage,
    perPage,
    onPerPageChange,
    columnOrderBy,
    onColumnOrderByChange,
    columnOrderDirection,
    onColumnOrderDirectionChange,
    selectedOrganizations,
    onRowSelectionChange,
    createColumns,
  } = props;

  const { t } = useTranslations();
  const navigate = useNavigate();

  const columns = useMemo(() => createColumns([
    {
      title: t('organizationsPage.organization'),
      field: 'name',
      render: (rowData) => (
        <TypographyWithTooltip variant="body2">
          {rowData.name}
        </TypographyWithTooltip>
      ),
      width: '25%',
    },
    {
      title: t('organizationsPage.region'),
      field: 'region',
      render: (rowData) => (
        <TypographyWithTooltip variant="body2">
          {rowData.region}
        </TypographyWithTooltip>
      ),
      width: '15%',
    },
    {
      title: t('organizationsPage.country'),
      field: 'country',
      render: (rowData) => (
        <TypographyWithTooltip variant="body2">
          {rowData.country}
        </TypographyWithTooltip>
      ),
      width: '15%',
    },
    {
      title: t('organizationsPage.vat'),
      field: 'vat',
      render: (rowData) => (
        <TypographyWithTooltip variant="body2">
          {rowData.vat}
        </TypographyWithTooltip>
      ),
      width: '15%',
    },
    {
      title: (
        <TypographyWithTooltip
          variant="subtitle1"
          tooltipProps={COLUMN_TOOLTIP_PROPS}
          lineClamp={5}
          title={t('organizationsPage.committedLicenses.columnTooltip')}
        >
          {t('organizationsPage.committedLicenses')}
        </TypographyWithTooltip>
      ),
      field: 'committedLicenseCount',
      render: (rowData) => (
        <TypographyWithTooltip variant="body2">
          {rowData.committedLicenseCount}
        </TypographyWithTooltip>
      ),
      width: '15%',
    },
    {
      title: (
        <TypographyWithTooltip
          variant="subtitle1"
          tooltipProps={COLUMN_TOOLTIP_PROPS}
          lineClamp={5}
          title={t('organizationsPage.licenses.columnTooltip')}
        >
          {t('organizationsPage.licenses')}
        </TypographyWithTooltip>
      ),
      field: 'licenseCount',
      render: (rowData) => (
        <TypographyWithTooltip variant="body2">
          {rowData.licenseCount}
        </TypographyWithTooltip>
      ),
      width: '15%',
    },
  ]), [createColumns, t]);

  const handleOrganizationRowClick = useCallback<
    NonNullable<IMaterialTableProps<IOrganizationsTableRow>['onRowClick']>
  >((event, organization) => {
    const { organizationId } = organization!;
    if (partnerId) {
      navigate(`${PageRouteEnum.Partners}/${partnerId}/${organizationId}`);
      return;
    }
    navigate(`${PageRouteEnum.Organizations}/${organizationId}`);
  }, [partnerId, navigate]);

  return (
    <Table
      t={t}
      totalItems={total}
      columns={columns}
      data={organizations}
      isLoading={isLoading}
      options={TABLE_OPTIONS}
      activePage={activePage}
      onActivePageChange={setActivePage}
      perPage={perPage}
      onPerPageChange={onPerPageChange}
      columnOrderBy={columnOrderBy}
      onColumnOrderByChange={onColumnOrderByChange}
      columnOrderDirection={columnOrderDirection}
      onColumnOrderDirectionChange={onColumnOrderDirectionChange}
      selectedRows={selectedOrganizations}
      onSelectionChange={onRowSelectionChange}
      localization={localization}
      onRowClick={handleOrganizationRowClick}
      rowSelectByKey="organizationId"
      rowHeight={51}
    />
  );
};

export default memo(OrganizationsTable);
