import { memo, useCallback, useMemo } from 'react';
import { pick } from 'lodash';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  ReactHookFormAutocomplete,
  ReactHookFormSwitch,
  ReactHookFormSelect,
  Typography,
  Button,
  MenuItem,
  GlobeIcon,
  B1,
  S4,
  S6,
  useTranslations,
  getCitiesByCountryName,
  validateEmail,
  validateAddress,
  validateVAT,
  validatePhone,
  validateZIPCode,
  maxLengthValidator,
  validateDomain,
  FormHiddenInput,
  emptyStringValidator,
  ReactHookFormTextField,
  ISwitchProps,
  IAutocompleteProps,
  TSelectProps,
} from '@uniqkey-frontend/shared-app';
import { useForm } from 'react-hook-form';
import { CreatePartnerOrganizationRequest } from '@uniqkey-backend-partner/api-client';
import { COUNTRIES_LIST } from '../../../../helpers/countries';
import { useLanguages } from '../../../../hooks/reactQuery';

interface ICreateOrganizationModalFormValue extends Omit<
  CreatePartnerOrganizationRequest, 'country' | 'city' | 'committedLicenseCount'
> {
  country: CreatePartnerOrganizationRequest['country'] | null;
  city: CreatePartnerOrganizationRequest['city'] | null;
  committedLicenseCount: string;
  isCommittedLicenseCountFieldEnabled: boolean;
}

interface ICreateOrganizationModalProps {
  isOpen: boolean;
  isLoading: boolean;
  onSubmit: (params: CreatePartnerOrganizationRequest) => Promise<void> | void;
  onClose: () => void;
}

const CHIP_PROPS = { icon: <GlobeIcon /> };

const CreateOrganizationModal = (props: ICreateOrganizationModalProps) => {
  const {
    isOpen, isLoading, onSubmit, onClose,
  } = props;

  const { t } = useTranslations();
  const { languages, isLoading: areLanguagesLoading } = useLanguages();

  const {
    handleSubmit,
    formState: {
      isDirty, errors, touchedFields,
    },
    control,
    setValue,
    setFocus,
    resetField,
    watch,
  } = useForm<ICreateOrganizationModalFormValue>({
    defaultValues: {
      name: '',
      country: null,
      billingEmail: '',
      city: null,
      vat: '',
      address: '',
      phone: '',
      zip: '',
      committedLicenseCount: '',
      isCommittedLicenseCountFieldEnabled: false,
      domainNames: [],
      firstAdminName: '',
      firstAdminEmail: '',
      language: 'da',
    },
    mode: 'all',
  });

  const [
    selectedCountry,
    isCommittedLicenseCountFieldEnabled,
    selectedCommittedLicenseCount,
  ] = watch([
    'country',
    'isCommittedLicenseCountFieldEnabled',
    'committedLicenseCount',
  ]);

  const citiesOptions = useMemo(() => (selectedCountry
    ? getCitiesByCountryName(selectedCountry)
    : []
  ), [selectedCountry]);

  const handleFormSubmit = useCallback((formValue: ICreateOrganizationModalFormValue) => {
    const { committedLicenseCount, billingEmail, firstAdminEmail } = formValue;
    const parsedLicensesAmount = parseInt(committedLicenseCount, 10);
    onSubmit({
      ...pick(formValue, [
        'name',
        'country',
        'city',
        'vat',
        'address',
        'phone',
        'zip',
        'domainNames',
        'firstAdminName',
        'language',
      ]),
      committedLicenseCount: Number.isNaN(parsedLicensesAmount) ? null : parsedLicensesAmount,
      billingEmail: billingEmail.trim(),
      firstAdminEmail: firstAdminEmail.trim(),
    } as CreatePartnerOrganizationRequest);
  }, [onSubmit]);

  const handleCountryChange = useCallback(() => {
    setValue('city', null);
  }, [setValue]);

  const handleIsCommittedLicenseCountFieldEnabledChange = useCallback<
    NonNullable<ISwitchProps['onChange']>
  >((event, checked) => {
    if (checked) {
      setValue('committedLicenseCount', '0');
      requestAnimationFrame(() => {
        setFocus('committedLicenseCount', { shouldSelect: true });
      });
    } else {
      resetField('committedLicenseCount');
    }
  }, [setValue, setFocus, resetField]);

  const licensesAmountInputLabelProps = useMemo(() => {
    if (!isCommittedLicenseCountFieldEnabled) {
      return { shrink: false }; // force the label to take default placement
    }
    if (selectedCommittedLicenseCount) {
      return { shrink: true }; // force the label to go up if there is a value
    }
    return undefined; // use default behaviour when there is no value
  }, [isCommittedLicenseCountFieldEnabled, selectedCommittedLicenseCount]);

  const handleDomainNamesGetOptionLabel = useCallback<
    NonNullable<IAutocompleteProps['getOptionLabel']>
  >(
    (option) => option.toLowerCase(),
    [],
  );

  const handleDomainNamesChange = useCallback<
    NonNullable<IAutocompleteProps['onChange']>
  >((event, selectedOptions) => {
    const processedSelectedOptions = (selectedOptions as string[]).map(
      (option) => option.toLowerCase(),
    );
    /*
      The default behaviour of this autocomplete - validate after 'Enter'
      or 'Create' button click. 'shouldValidate: true', makes validation
      after every change in the field.
    */
    setValue('domainNames', processedSelectedOptions, { shouldValidate: true });
  }, [setValue]);

  const handleLanguageChange = useCallback<
    NonNullable<TSelectProps['onChange']>
  >((event) => {
    // the 'Create' button should be enabled directly after the language change, not after blur
    setValue('language', event.target.value as string, { shouldTouch: true });
  }, [setValue]);

  const disabled = !isDirty && !touchedFields.language;

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullWidth
      maxWidth="md"
      clickOutsideToClose={!isLoading}
    >
      <form onSubmit={handleSubmit(handleFormSubmit)} autoComplete="off" noValidate>
        <DialogTitle isLoading={isLoading} onClose={onClose}>
          {t('createOrganizationModal.title')}
        </DialogTitle>
        <DialogContent>
          <Grid container direction="row" rowSpacing={4} columnSpacing={2}>
            <Grid item xs={12} mb={-1}>
              <Typography variant="subtitle1" color={S4}>
                <span>{t('createOrganizationModal.subTitles.organizationDetails')}</span>
                <span>:</span>
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <FormHiddenInput />
              <ReactHookFormTextField
                name="name"
                control={control}
                fullWidth
                autoFocus
                autoComplete="none"
                label={`${t('createOrganizationModal.organizationName.label')}*`}
                placeholder={t('createOrganizationModal.organizationName.placeholder')}
                error={!!errors.name}
                helperText={errors.name?.message}
                rules={{
                  required: t('validation.required'),
                  validate: (value) => (
                    emptyStringValidator(value as string) ? t('validation.required') : true
                  ),
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <ReactHookFormAutocomplete
                t={t}
                name="country"
                onChange={handleCountryChange}
                options={COUNTRIES_LIST}
                control={control}
                label={`${t('createOrganizationModal.country.label')}*`}
                placeholder={t('createOrganizationModal.country.placeholder')}
                error={!!errors.country}
                helperText={errors.country?.message}
                disableAutoFill
                rules={{ required: t('validation.required') }}
              />
            </Grid>
            <Grid item xs={6}>
              <ReactHookFormTextField
                name="billingEmail"
                control={control}
                fullWidth
                autoComplete="none"
                label={`${t('createOrganizationModal.billingEmail.label')}*`}
                placeholder={t('createOrganizationModal.billingEmail.placeholder')}
                error={!!errors.billingEmail}
                helperText={errors.billingEmail?.message}
                rules={{
                  required: t('validation.required'),
                  validate: {
                    isValid: (v) => validateEmail(v as string) || t('validation.invalidEmail'),
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <ReactHookFormAutocomplete
                t={t}
                name="city"
                freeSolo
                options={citiesOptions}
                control={control}
                label={`${t('createOrganizationModal.city.label')}*`}
                placeholder={t('createOrganizationModal.city.placeholder')}
                error={!!errors.city}
                helperText={errors.city?.message}
                disableAutoFill
                disabled={!selectedCountry}
                rules={{
                  validate: {
                    isValid: (v) => validateAddress(v as string) || t('validation.invalidCity'),
                    required: (v) => (selectedCountry && !v ? t('validation.required') : true),
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <ReactHookFormTextField
                name="vat"
                control={control}
                fullWidth
                autoComplete="none"
                label={`${t('createOrganizationModal.vat.label')}*`}
                placeholder={t('createOrganizationModal.vat.placeholder')}
                error={!!errors.vat}
                helperText={errors.vat?.message}
                rules={{
                  required: t('validation.required'),
                  validate: {
                    isValid: (v) => validateVAT(v as string)
                        || t('validation.invalidVAT'),
                    maxLength: (v) => maxLengthValidator(20, v as string)
                        || t('validation.maxLength', { length: 20 }),
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <ReactHookFormTextField
                name="address"
                control={control}
                fullWidth
                autoComplete="none"
                label={`${t('createOrganizationModal.address.label')}*`}
                placeholder={t('createOrganizationModal.address.placeholder')}
                error={!!errors.address}
                helperText={errors.address?.message}
                rules={{
                  required: t('validation.required'),
                  validate: {
                    isValid: (v) => validateAddress(v as string)
                        || t('validation.invalidAddress'),
                    required: (value) => (emptyStringValidator(value as string)
                      ? t('validation.required')
                      : true),
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <ReactHookFormTextField
                name="phone"
                control={control}
                fullWidth
                autoComplete="none"
                label={`${t('createOrganizationModal.phone.label')}*`}
                placeholder={t('createOrganizationModal.phone.placeholder')}
                error={!!errors.phone}
                helperText={errors.phone?.message}
                rules={{
                  required: t('validation.required'),
                  validate: {
                    isValid: (v) => validatePhone(v as string)
                        || t('validation.invalidPhone'),
                    maxLength: (v) => maxLengthValidator(19, v as string)
                        || t('validation.maxLength', { length: 19 }),
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <ReactHookFormTextField
                name="zip"
                control={control}
                fullWidth
                autoComplete="none"
                label={`${t('createOrganizationModal.zipCode.label')}*`}
                placeholder={t('createOrganizationModal.zipCode.placeholder')}
                error={!!errors.zip}
                helperText={errors.zip?.message}
                rules={{
                  required: t('validation.required'),
                  validate: {
                    isValid: (v) => validateZIPCode(v as string)
                        || t('validation.invalidZIPCode'),
                    maxLength: (v) => maxLengthValidator(12, v as string)
                        || t('validation.maxLength', { length: 12 }),
                    required: (value) => (emptyStringValidator(value as string)
                      ? t('validation.required')
                      : true),
                  },
                }}
              />
            </Grid>
            <Grid item mt={-2} xs={12}>
              <Typography variant="body2" color={S4} ml={3}>
                {t('createOrganizationModal.subTitles.committedLicenses')}
              </Typography>
            </Grid>
            <Grid container item alignItems="center" justifyContent="space-between" mt={-4} xs={6}>
              <Grid item ml={3}>
                <ReactHookFormSwitch
                  name="isCommittedLicenseCountFieldEnabled"
                  onChange={handleIsCommittedLicenseCountFieldEnabledChange}
                  control={control}
                  label={(
                    <Typography variant="body1" color={S6} ml={1}>
                      {t(isCommittedLicenseCountFieldEnabled ? 'common.yes' : 'common.no')}
                    </Typography>
                  )}
                />
              </Grid>
              <Grid item>
                <ReactHookFormTextField
                  name="committedLicenseCount"
                  control={control}
                  type="number"
                  autoComplete="none"
                  label={t('createOrganizationModal.committedLicenseCount.label')}
                  placeholder={t('createOrganizationModal.committedLicenseCount.placeholder')}
                  error={!!errors.committedLicenseCount}
                  helperText={errors.committedLicenseCount?.message}
                  disabled={!isCommittedLicenseCountFieldEnabled}
                  InputLabelProps={licensesAmountInputLabelProps}
                  rules={{
                    min: 0,
                    validate: {
                      required: (v) => (
                        isCommittedLicenseCountFieldEnabled
                        && Number.isNaN(parseInt(v as string, 10))
                          ? t('validation.required')
                          : true
                      ),
                      isValid: (v) => (
                        parseInt(v as string, 10) < 0
                          ? t('validation.invalidFormat')
                          : true
                      ),
                    },
                  }}
                />
              </Grid>
            </Grid>
            <Grid item mt={-4} xs={6}>
              <ReactHookFormAutocomplete
                t={t}
                name="domainNames"
                getOptionLabel={handleDomainNamesGetOptionLabel}
                onChange={handleDomainNamesChange}
                freeSolo
                multiple
                control={control}
                label={`${t('createOrganizationModal.verifiedDomains.label')}*`}
                placeholder={t('createOrganizationModal.verifiedDomains.placeholder')}
                error={!!errors.domainNames}
                helperText={errors.domainNames?.message?.split('|')}
                ChipProps={CHIP_PROPS}
                disableAutoFill
                rules={{
                  required: t('validation.required'),
                  validate: {
                    isValid: (domains) => {
                      const domainNamesErrors = (domains as string[]).reduce((acc, domain) => {
                        if (!validateDomain(domain)) {
                          acc.push(t('validation.invalidValue', { value: domain }));
                        }
                        return acc;
                      }, []);
                      return domainNamesErrors.length ? domainNamesErrors.join('|') : true;
                    },
                  },
                }}
              />
            </Grid>
            <Grid item mb={-1} xs={12}>
              <Typography variant="subtitle1" color={S4}>
                <span>{t('createOrganizationModal.subTitles.adminDetails')}</span>
                <span>:</span>
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <ReactHookFormTextField
                name="firstAdminName"
                control={control}
                fullWidth
                autoComplete="none"
                label={`${t('createOrganizationModal.adminFullName.label')}*`}
                placeholder={t('createOrganizationModal.adminFullName.placeholder')}
                error={!!errors.firstAdminName}
                helperText={errors.firstAdminName?.message}
                rules={{
                  required: t('validation.required'),
                  validate: (value) => (
                    emptyStringValidator(value as string) ? t('validation.required') : true
                  ),
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <ReactHookFormTextField
                name="firstAdminEmail"
                control={control}
                fullWidth
                autoComplete="none"
                label={`${t('createOrganizationModal.adminEmail.label')}*`}
                placeholder={t('createOrganizationModal.adminEmail.placeholder')}
                error={!!errors.firstAdminEmail}
                helperText={errors.firstAdminEmail?.message}
                rules={{
                  required: t('validation.required'),
                  validate: {
                    isValid: (v) => validateEmail(v as string) || t('validation.invalidEmail'),
                  },
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container columnSpacing={2}>
            {
              !areLanguagesLoading && (
                <Grid container item alignItems="center" xs>
                  <Typography variant="body1" color={B1} mr={1}>
                    <span>{t('createOrganizationModal.languages.label')}</span>
                    <span>:</span>
                  </Typography>
                  <ReactHookFormSelect
                    name="language"
                    control={control}
                    onChange={handleLanguageChange}
                  >
                    {
                      languages.map((currLanguage) => (
                        <MenuItem key={currLanguage.code} value={currLanguage.code}>
                          {currLanguage.localized_name}
                        </MenuItem>
                      ))
                    }
                  </ReactHookFormSelect>
                </Grid>
              )
            }

            <Grid item xs={2.5}>
              <Button fullWidth isLoading={isLoading} disabled={disabled} type="submit">
                {t('common.create')}
              </Button>
            </Grid>
            <Grid item xs={2.5}>
              <Button fullWidth variant="outlined" disabled={isLoading} onClick={onClose}>
                {t('common.cancel')}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default memo(CreateOrganizationModal);
