import axios, { AxiosInstance } from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { getJWTToken, logout } from '../services/authService';
import config from '../config';
import refreshTokens from '../services/tokensManager';

const PARTNER_API_BASE_URL = config.getPartnerApiUrl();

const publicAxiosInstance = axios.create({
  baseURL: PARTNER_API_BASE_URL,
  timeout: config.requestTimeout,
});

const axiosInstance = axios.create({
  baseURL: PARTNER_API_BASE_URL,
  timeout: config.requestTimeout,
});

const axiosInstanceWithoutLogout = axios.create({
  baseURL: PARTNER_API_BASE_URL,
  timeout: config.requestTimeout,
});

interface IHandleAuthRefreshParams {
  withLogout?: boolean;
}

const handleAuthRefresh = (params: IHandleAuthRefreshParams) => async (failedRequest: any) => {
  const { withLogout = true } = params;
  try {
    await refreshTokens();

    const jwtToken = getJWTToken();

    if (!jwtToken) throw new Error('jwt token not found');

    // eslint-disable-next-line no-param-reassign
    failedRequest.response.config.headers.Authorization = `Bearer ${jwtToken}`;
  } catch (error) {
    if (withLogout) {
      logout({ showMessage: true });
    }
  }
};

const createAuthAttachInterceptor = (instance: AxiosInstance) => {
  instance.interceptors.request.use(async (axiosRequestConfig) => {
    const jwtToken = getJWTToken();
    // @ts-ignore
    // eslint-disable-next-line no-param-reassign
    axiosRequestConfig.headers.Authorization = `Bearer ${jwtToken}`;
    return axiosRequestConfig;
  });
};

createAuthRefreshInterceptor(axiosInstance, handleAuthRefresh({ withLogout: true }));

createAuthRefreshInterceptor(axiosInstanceWithoutLogout, handleAuthRefresh({ withLogout: false }));

createAuthAttachInterceptor(axiosInstance);
createAuthAttachInterceptor(axiosInstanceWithoutLogout);

export {
  publicAxiosInstance,
  axiosInstance,
  axiosInstanceWithoutLogout,
};
