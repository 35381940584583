import { useCallback } from 'react';
import { AxiosRequestConfig } from 'axios';
import {
  CreatePartnerOrganizationRequest,
  NoContentResult,
  GetOrganizationsResponse,
  GetOrganizationByIdResponse,
  GetVerifiedDomainsResponse,
  GetOrganizationHistoricLicenseDataResponse,
  GetOrganizationAdminsResponse,
  ExportOrganizationLicenseDataResponse,
  ExportPeriod,
  UpdatePartnerOrganizationRequest,
  GetOrganizationPortalAccessInfoResponse,
} from '@uniqkey-backend-partner/api-client';
import { useApiClients } from '../../contexts/APIClientsContext';
import { dataExtractor } from '../../helpers/apiClients';
import {
  TGetOrganizationLicensesHistoryMethod,
  TGetOrganizationsMethod,
  TGetOrganizationAdminsMethod,
  TGetOrganizationVerifiedDomainsMethod,
} from './interfaces';

const usePartnerOrganizationsAPI = () => {
  const { partnerOrganizationsAPIClient } = useApiClients();

  const createOrganization = useCallback(
    (params: CreatePartnerOrganizationRequest, options?: AxiosRequestConfig)
      : Promise<NoContentResult> => partnerOrganizationsAPIClient
      .apiV1PartnerOrganizationsPost(params, options)
      .then(dataExtractor<NoContentResult>()),
    [partnerOrganizationsAPIClient],
  );

  const getOrganizations = useCallback<TGetOrganizationsMethod>(
    (params) => {
      const {
        page,
        pageLength,
        partnerId = undefined,
        countryFilter = undefined,
        searchQuery = undefined,
        orderPropertyName = undefined,
        isDescending = undefined,
        cancelToken = undefined,
      } = params;
      return partnerOrganizationsAPIClient.apiV1PartnerOrganizationsGet(
        page,
        pageLength,
        partnerId,
        countryFilter,
        searchQuery,
        orderPropertyName,
        isDescending,
        { cancelToken },
      ).then(dataExtractor<GetOrganizationsResponse>());
    },
    [partnerOrganizationsAPIClient],
  );

  const getOrganizationById = useCallback(
    (organizationId: string, options?: AxiosRequestConfig)
      : Promise<GetOrganizationByIdResponse> => partnerOrganizationsAPIClient
      .apiV1PartnerOrganizationsOrganizationIdGet(organizationId, options)
      .then(dataExtractor<GetOrganizationByIdResponse>()),
    [partnerOrganizationsAPIClient],
  );

  const getOrganizationVerifiedDomains = useCallback<TGetOrganizationVerifiedDomainsMethod>(
    (params) => {
      const {
        organizationId,
        page,
        pageLength,
        orderPropertyName = undefined,
        isDescending = undefined,
        cancelToken = undefined,
      } = params;
      return partnerOrganizationsAPIClient.apiV1PartnerOrganizationsGetVerifiedDomainsGet(
        organizationId,
        page,
        pageLength,
        orderPropertyName,
        isDescending,
        { cancelToken },
      ).then(dataExtractor<GetVerifiedDomainsResponse>());
    },
    [partnerOrganizationsAPIClient],
  );

  const getOrganizationLicensesHistory = useCallback<TGetOrganizationLicensesHistoryMethod>(
    (params) => {
      const {
        organizationId,
        page,
        pageLength,
        orderPropertyName = undefined,
        isDescending = undefined,
        cancelToken = undefined,
      } = params;
      return partnerOrganizationsAPIClient.apiV1PartnerOrganizationsGetHistoricLicenseDataGet(
        organizationId,
        page,
        pageLength,
        orderPropertyName,
        isDescending,
        { cancelToken },
      ).then(dataExtractor<GetOrganizationHistoricLicenseDataResponse>());
    },
    [partnerOrganizationsAPIClient],
  );

  const getOrganizationAdmins = useCallback<TGetOrganizationAdminsMethod>(
    (params) => {
      const {
        organizationId,
        page,
        pageLength,
        orderPropertyName = undefined,
        isDescending = undefined,
        cancelToken = undefined,
      } = params;
      return partnerOrganizationsAPIClient.apiV1PartnerOrganizationsGetAdminsGet(
        organizationId,
        page,
        pageLength,
        orderPropertyName,
        isDescending,
        { cancelToken },
      ).then(dataExtractor<GetOrganizationAdminsResponse>());
    },
    [partnerOrganizationsAPIClient],
  );

  const getOrganizationExportLicenseData = useCallback(
    (organizationId: string, exportPeriod: ExportPeriod, options?: AxiosRequestConfig)
      : Promise<ExportOrganizationLicenseDataResponse> => partnerOrganizationsAPIClient
      .apiV1PartnerOrganizationsExportLicenseDataGet(organizationId, exportPeriod, options)
      .then(dataExtractor<ExportOrganizationLicenseDataResponse>()),
    [partnerOrganizationsAPIClient],
  );

  const updateOrganization = useCallback(
    (
      updatePartnerOrganizationRequest?: UpdatePartnerOrganizationRequest,
      options?: AxiosRequestConfig,
    ): Promise<NoContentResult> => partnerOrganizationsAPIClient
      .apiV1PartnerOrganizationsPut(updatePartnerOrganizationRequest, options)
      .then(dataExtractor<NoContentResult>()),
    [partnerOrganizationsAPIClient],
  );

  const getOrganizationAccessInfo = useCallback(
    (
      organizationId: string,
      options?: AxiosRequestConfig,
    ): Promise<GetOrganizationPortalAccessInfoResponse> => partnerOrganizationsAPIClient
      .apiV1PartnerOrganizationsGetAccessInfoGet(organizationId, options)
      .then(dataExtractor<GetOrganizationPortalAccessInfoResponse>()),
    [partnerOrganizationsAPIClient],
  );

  const requestOrganizationLogin = useCallback(
    (organizationId: string, options?: AxiosRequestConfig)
      : Promise<NoContentResult> => partnerOrganizationsAPIClient
      .apiV1PartnerOrganizationsRequestOrganizationLoginPost(organizationId, options)
      .then(dataExtractor<NoContentResult>()),
    [partnerOrganizationsAPIClient],
  );

  return ({
    createOrganization,
    getOrganizations,
    getOrganizationById,
    getOrganizationVerifiedDomains,
    getOrganizationLicensesHistory,
    getOrganizationAdmins,
    getOrganizationExportLicenseData,
    updateOrganization,
    getOrganizationAccessInfo,
    requestOrganizationLogin,
  });
};

export default usePartnerOrganizationsAPI;
