import {
  Dispatch, SetStateAction, useCallback, useState,
} from 'react';
import { upperFirst } from 'lodash';
import {
  useSnackbar,
  useTable,
  useTableFetch,
  useTranslations,
  useTableLocalization,
  useTablePreserverActivePageValidator,
  useTablePreserverDecoder,
  useTablePreserverEncoder,
  usePubSub,
  buildTablePreserverDecoderConfigItem,
  TablePreserverCommonInitialTableValues,
  IUseTableReturn,
  IUseTableResetTableMethodsReturn,
  IUseTableFetchReturn,
  IUseTableLocalizationReturn,
  TUseTablePreserverDecoderConfigs,
  TablePreserverDecoderConfigItemTypeEnum,
  TSetSearchParams,
  IUseFilterButtonReturn,
  useFilterButton,
} from '@uniqkey-frontend/shared-app';
import {
  GetPartnerUsersForAdminResponsePartnerUser,
  PartnerUserRole,
  PartnerUsersForAdminOrderProperty, KeysState,
} from '@uniqkey-backend-partner/api-client';
import { useSearchParams } from 'react-router-dom';
import PubSubEventEnum from '../../../enums/PubSubEventEnum';
import {
  IPartnerUsersForAdminTableRow,
} from '../../../components/tables/PartnerUsersForAdminTable';
import {
  IGetPartnerUsersForAdminParams,
  TGetPartnerUsersForAdminMethod,
} from '../../usePartnerUsersAPI/interfaces';
import usePartnerUsersAPI from '../../usePartnerUsersAPI';
import {
  IPartnerUsersForAdminListFilterSubmitResult,
} from '../../../pages/PartnerUsersForAdminPage/components/PartnerUsersForAdminListFilter';

export interface IUsePartnerUsersForAdminTableProps {
  noDataMessageKey: string;
}

export interface IUsePartnerUsersForAdminTableReturn extends
  Omit<IUseTableReturn<IPartnerUsersForAdminTableRow>, 'selectedRows' | 'resetTableMethods'>,
  Pick<IUseTableResetTableMethodsReturn, 'resetActivePage' | 'resetSelectedRows'>,
  Omit<IUseTableFetchReturn<GetPartnerUsersForAdminResponsePartnerUser>, 'data'>,
  IUseFilterButtonReturn
{
  partnerUsersForAdmin: IUseTableFetchReturn<GetPartnerUsersForAdminResponsePartnerUser>['data'];
  selectedPartnerUsersForAdmin: IUseTableReturn<IPartnerUsersForAdminTableRow>['selectedRows'];
  localization: IUseTableLocalizationReturn['localization'];
  searchQuery: IGetPartnerUsersForAdminParams['searchQuery'];
  setSearchQuery: Dispatch<SetStateAction<IGetPartnerUsersForAdminParams['searchQuery']>>;
  filterValues: IPartnerUsersForAdminListFilterSubmitResult;
  setFilterValues: Dispatch<SetStateAction<IPartnerUsersForAdminListFilterSubmitResult>>
}

interface ITablePreservationConfigs extends
  Omit<IGetPartnerUsersForAdminParams,
    'page' | 'pageLength' | 'orderPropertyName' | 'isDescending' | 'cancelToken'
  >,
  Pick<IUseTableReturn<IPartnerUsersForAdminTableRow>, 'columnOrderBy' | 'columnOrderDirection'>
{
  activePage: IGetPartnerUsersForAdminParams['page'];
  perPage: IGetPartnerUsersForAdminParams['pageLength'];
}

const PRESERVATION_CONFIGS: TUseTablePreserverDecoderConfigs<ITablePreservationConfigs> = {
  activePage: true,
  perPage: true,
  searchQuery: true,
  columnOrderBy: buildTablePreserverDecoderConfigItem(
    TablePreserverDecoderConfigItemTypeEnum.ENUM,
    { enumToCheck: PartnerUsersForAdminOrderProperty },
  ),
  columnOrderDirection: true,
  roleFilter: buildTablePreserverDecoderConfigItem(
    TablePreserverDecoderConfigItemTypeEnum.ENUM,
    { enumToCheck: PartnerUserRole },
  ),
  keysStateFilter: buildTablePreserverDecoderConfigItem(
    TablePreserverDecoderConfigItemTypeEnum.ENUM,
    { enumToCheck: KeysState },
  ),
};

const usePartnerUsersForAdminTable = (
  props: IUsePartnerUsersForAdminTableProps,
): IUsePartnerUsersForAdminTableReturn => {
  const {
    noDataMessageKey,
  } = props;

  const { t } = useTranslations();
  const { showError } = useSnackbar();
  const { getPartnerUsersForAdmin } = usePartnerUsersAPI();
  const [searchParams, setSearchParams] = useSearchParams();

  const handleOnRequestError = useCallback(() => {
    showError({ text: t('common.somethingWentWrong') });
  }, [showError, t]);

  const {
    activePage: initialActivePage,
    perPage: initialPerPage,
    searchQuery: initialSearchQuery,
    roleFilter: initialRoleFilter,
    keysStateFilter: initialKeysStateFilter,
    columnOrderBy: initialColumnOrderBy,
    columnOrderDirection: initialColumnOrderDirection,
  } = useTablePreserverDecoder<ITablePreservationConfigs>(searchParams, PRESERVATION_CONFIGS);

  const [searchQuery, setSearchQuery] = useState<
    IGetPartnerUsersForAdminParams['searchQuery']
  >(() => initialSearchQuery);
  const [filterValues, setFilterValues] = useState<
    IPartnerUsersForAdminListFilterSubmitResult
  >({
    roleFilter: initialRoleFilter,
    keysStateFilter: initialKeysStateFilter,
  });

  const { isFilterActive, numberOfActiveFilters } = useFilterButton(filterValues);

  const {
    activePage,
    perPage,
    columnOrderBy,
    columnOrderDirection,
    selectedRows: selectedPartnerUsersForAdmin,
    resetTableMethods,
    ...restTableProps
  } = useTable<IPartnerUsersForAdminTableRow>({
    initialActivePage,
    initialPerPage,
    initialColumnOrderBy,
    initialColumnOrderDirection,
  });

  const {
    resetTable,
    resetActivePage,
    resetSelectedRows,
  } = resetTableMethods;

  const handleTableReset = useCallback(() => {
    resetTable();
    setSearchQuery(TablePreserverCommonInitialTableValues.searchQuery);
    setFilterValues({
      roleFilter: undefined,
      keysStateFilter: undefined,
    });
  }, [resetTable]);
  usePubSub(PubSubEventEnum.RESET_TABLE, handleTableReset);

  const {
    data: partnerUsersForAdmin, isLoading, isFetchedAfterMount, total, refetch,
  } = useTableFetch<
    IGetPartnerUsersForAdminParams,
    TGetPartnerUsersForAdminMethod,
    GetPartnerUsersForAdminResponsePartnerUser
  >({
    request: getPartnerUsersForAdmin,
    params: {
      page: activePage,
      pageLength: perPage,
      roleFilter: filterValues.roleFilter,
      keysStateFilter: filterValues.keysStateFilter,
      searchQuery,
      orderPropertyName: PartnerUsersForAdminOrderProperty[
        upperFirst(columnOrderBy) as keyof typeof PartnerUsersForAdminOrderProperty
      ],
      isDescending: columnOrderDirection ? columnOrderDirection === 'desc' : undefined,
    },
    onRequestError: handleOnRequestError,
    autoRefetch: true,
  });

  const { localization } = useTableLocalization({
    searchQuery,
    isFilterActive,
    noDataMessageKey,
    isLoading,
  });

  useTablePreserverActivePageValidator({
    activePage, resetActivePage, perPage, total, isFetchedAfterMount,
  });

  useTablePreserverEncoder<ITablePreservationConfigs>(setSearchParams as TSetSearchParams, {
    activePage,
    perPage,
    searchQuery,
    columnOrderBy,
    columnOrderDirection,
    roleFilter: filterValues.roleFilter,
    keysStateFilter: filterValues.keysStateFilter,
  });

  return {
    partnerUsersForAdmin,
    isLoading,
    total,
    refetch,
    activePage,
    perPage,
    columnOrderBy,
    columnOrderDirection,
    selectedPartnerUsersForAdmin,
    localization,
    searchQuery,
    setSearchQuery,
    filterValues,
    setFilterValues,
    isFilterActive,
    numberOfActiveFilters,
    resetActivePage,
    resetSelectedRows,
    ...restTableProps,
  };
};

export default usePartnerUsersForAdminTable;
