import { useMemo, memo } from 'react';
import {
  Table,
  TypographyWithTooltip,
  useTranslations,
  IUseTableReturn,
  IUseTableFetchReturn,
  IUseTableLocalizationReturn,
  IMaterialTableOptions,
} from '@uniqkey-frontend/shared-app';
import { GetAccessRightsResponseModel } from '@uniqkey-backend-partner/api-client';
import ACLEnum from '../../../enums/ACLEnum';
import { ACLS_TRANSLATION_KEYS } from '../../../constants';

export interface IRoleTableRow extends GetAccessRightsResponseModel {}

interface IRoleTableProps extends
  Omit<IUseTableReturn<IRoleTableRow>, 'selectedRows' | 'resetTableMethods'>,
  Omit<IUseTableFetchReturn<GetAccessRightsResponseModel>, 'data' | 'refetch'>
{
  roleACLs: IUseTableFetchReturn<GetAccessRightsResponseModel>['data'];
  selectedRoleACLs: IUseTableReturn<IRoleTableRow>['selectedRows'];
  localization: IUseTableLocalizationReturn['localization'];
}

const TABLE_OPTIONS: IMaterialTableOptions<IRoleTableRow> = {
  selection: false,
  sorting: false,
};

/* eslint-disable max-len */
const ACCESS_RIGHTS_DESCRIPTIONS_TRANSLATION_KEYS = {
  [ACLEnum.AuditLogExport]: 'rolePage.ACLsDescriptions.auditLogExport',
  [ACLEnum.AuditLogView]: 'rolePage.ACLsDescriptions.auditLogView',
  [ACLEnum.EmployeeAccountView]: 'rolePage.ACLsDescriptions.employeeAccountView',
  [ACLEnum.EmployeeAccountViewAuditLogs]: 'rolePage.ACLsDescriptions.employeeAccountViewAuditLogs',
  [ACLEnum.EmployeeAccountViewVaultPasswords]: 'rolePage.ACLsDescriptions.employeeAccountViewVaultPasswords',
  [ACLEnum.GroupAddOrganizations]: 'rolePage.ACLsDescriptions.groupAddOrganizations',
  [ACLEnum.GroupAddUsers]: 'rolePage.ACLsDescriptions.groupAddUsers',
  [ACLEnum.GroupCreate]: 'rolePage.ACLsDescriptions.groupCreate',
  [ACLEnum.GroupDelete]: 'rolePage.ACLsDescriptions.groupDelete',
  [ACLEnum.GroupEdit]: 'rolePage.ACLsDescriptions.groupEdit',
  [ACLEnum.GroupRemoveOrganizations]: 'rolePage.ACLsDescriptions.groupRemoveOrganizations',
  [ACLEnum.GroupRemoveUsers]: 'rolePage.ACLsDescriptions.groupRemoveUsers',
  [ACLEnum.GroupView]: 'rolePage.ACLsDescriptions.groupView',
  [ACLEnum.OrganizationCreate]: 'rolePage.ACLsDescriptions.organizationCreate',
  [ACLEnum.OrganizationEdit]: 'rolePage.ACLsDescriptions.organizationEdit',
  [ACLEnum.OrganizationExportList]: 'rolePage.ACLsDescriptions.organizationExportList',
  [ACLEnum.OrganizationView]: 'rolePage.ACLsDescriptions.organizationView',
  [ACLEnum.OrganizationViewAdmins]: 'rolePage.ACLsDescriptions.organizationViewAdmins',
  [ACLEnum.OrganizationViewAuditLogs]: 'rolePage.ACLsDescriptions.organizationViewAuditLogs',
  [ACLEnum.OrganizationViewDomains]: 'rolePage.ACLsDescriptions.organizationViewDomains',
  [ACLEnum.OrganizationViewLicenseHistory]: 'rolePage.ACLsDescriptions.organizationViewLicenseHistory',
  [ACLEnum.PartnerCreate]: 'rolePage.ACLsDescriptions.partnerCreate',
  [ACLEnum.PartnerEdit]: 'rolePage.ACLsDescriptions.partnerEdit',
  [ACLEnum.PartnerUserCreate]: 'rolePage.ACLsDescriptions.partnerUserCreate',
  [ACLEnum.PartnerUserDelete]: 'rolePage.ACLsDescriptions.partnerUserDelete',
  [ACLEnum.PartnerUserEdit]: 'rolePage.ACLsDescriptions.partnerUserEdit',
  [ACLEnum.PartnerUserEditRoles]: 'rolePage.ACLsDescriptions.partnerUserEditRoles',
  [ACLEnum.PartnerUserReset2Fa]: 'rolePage.ACLsDescriptions.partnerUserReset2FA',
  [ACLEnum.PartnerUserResetPassword]: 'rolePage.ACLsDescriptions.partnerUserResetPassword',
  [ACLEnum.PartnerUserView]: 'rolePage.ACLsDescriptions.partnerUserView',
  [ACLEnum.PartnerView]: 'rolePage.ACLsDescriptions.partnerView',
  [ACLEnum.PartnerViewLicenseHistory]: 'rolePage.ACLsDescriptions.partnerViewLicenseHistory',
  [ACLEnum.PartnerViewOrganizations]: 'rolePage.ACLsDescriptions.partnerViewOrganizations',
  [ACLEnum.PermissionView]: 'rolePage.ACLsDescriptions.permissionView',
  [ACLEnum.RequestOrganizationLogin]: 'rolePage.ACLsDescriptions.requestOrganizationLogin',
};
/* eslint-enable max-len */

const RoleTable = (props: IRoleTableProps) => {
  const {
    roleACLs,
    isLoading,
    total,
    localization,
    activePage,
    setActivePage,
    perPage,
    onPerPageChange,
    columnOrderBy,
    onColumnOrderByChange,
    columnOrderDirection,
    onColumnOrderDirectionChange,
    selectedRoleACLs,
    onRowSelectionChange,
    createColumns,
  } = props;

  const { t } = useTranslations();

  const columns = useMemo(() => createColumns([
    {
      title: t('rolePage.name'),
      field: 'accessRight',
      render: (rowData) => (
        <TypographyWithTooltip variant="body2">
          {t(ACLS_TRANSLATION_KEYS[
            rowData.accessRight as keyof typeof ACLS_TRANSLATION_KEYS
          ]) || rowData.accessRight}
        </TypographyWithTooltip>
      ),
      width: '25%',
    },
    {
      title: t('rolePage.description'),
      field: 'description',
      render: (rowData) => (
        <TypographyWithTooltip variant="body2">
          {t(ACCESS_RIGHTS_DESCRIPTIONS_TRANSLATION_KEYS[
            rowData.accessRight as keyof typeof ACCESS_RIGHTS_DESCRIPTIONS_TRANSLATION_KEYS
          ]) || t('common.notAvailableShort')}
        </TypographyWithTooltip>
      ),
      width: '75%',
    },
  ]), [createColumns, t]);

  return (
    <Table
      t={t}
      totalItems={total}
      columns={columns}
      data={roleACLs}
      isLoading={isLoading}
      options={TABLE_OPTIONS}
      activePage={activePage}
      onActivePageChange={setActivePage}
      perPage={perPage}
      onPerPageChange={onPerPageChange}
      columnOrderBy={columnOrderBy}
      onColumnOrderByChange={onColumnOrderByChange}
      columnOrderDirection={columnOrderDirection}
      onColumnOrderDirectionChange={onColumnOrderDirectionChange}
      selectedRows={selectedRoleACLs}
      onSelectionChange={onRowSelectionChange}
      localization={localization}
      rowSelectByKey="id"
      rowHeight={51}
    />
  );
};

export default memo(RoleTable);
