import { useCallback, memo, useState } from 'react';
import {
  useSnackbar,
  useTranslations,
} from '@uniqkey-frontend/shared-app';
import { GetPartnerUsersResponsePartnerUser } from '@uniqkey-backend-partner/api-client';
import { logException } from '../../../../services/sentryService';
import usePartnerUsersAPI from '../../../../hooks/usePartnerUsersAPI';
import useGroupsAPI from '../../../../hooks/useGroupsAPI';
import EntitySelectorModal, {
  TEntitySelectorModalOnSubmit,
} from '../../../../components/EntitySelectorModal';

interface IUsersSelectorModalProps {
  isOpen: boolean;
  onClose: () => void;
  groupId: string;
  onSubmit?: () => void;
  refetch: () => void;
}

interface IParsedEntity {
  id: GetPartnerUsersResponsePartnerUser['partnerUserId'];
  label: string;
}

const UsersSelectorModal = (props: IUsersSelectorModalProps) => {
  const {
    isOpen, onClose, groupId, onSubmit, refetch,
  } = props;

  const { t } = useTranslations();
  const { showSuccess, showError } = useSnackbar();
  const { getPartnerUsers } = usePartnerUsersAPI();
  const { addUsersToGroup } = useGroupsAPI();

  const [isUsersSelectorModalLoading, setIsUsersSelectorModalLoading] = useState(false);

  const handleAutocompleteRequest = useCallback(async (searchQuery: string) => {
    try {
      const { data } = await getPartnerUsers({ page: 1, pageLength: 100, searchQuery });
      return data!; // TODO: remove '!' when data in interface will be not optional
    } catch (e) {
      showError({
        text: t('common.somethingWentWrong'),
      });
      logException(e, {
        message: 'UsersSelectorModal/handleAutocompleteRequest exception',
      });
      return [];
    }
  }, [getPartnerUsers, showError, t]);

  const handleSubmitEntitySelectorModal = useCallback<
    TEntitySelectorModalOnSubmit<IParsedEntity[]>
  >(async (value) => {
    try {
      const partnerUserIds = value.map((el: { id: string }) => el.id);
      setIsUsersSelectorModalLoading(true);
      await addUsersToGroup({ partnerUserIds, groupId });
      showSuccess({
        text: t('addUsersToGroupModal.userAddedToGroup', { count: partnerUserIds.length }),
      });
      if (onSubmit) {
        onSubmit();
      }
      refetch();
      onClose();
    } catch (e) {
      showError({
        text: t('common.somethingWentWrong'),
      });
      logException(e, {
        message: 'UsersSelectorModal/handleSubmitEntitySelectorModal exception',
      });
    } finally {
      setIsUsersSelectorModalLoading(false);
    }
  }, [refetch, addUsersToGroup, groupId, onSubmit, onClose, showError, showSuccess, t]);

  const handleResponseEntitySelectorModal = useCallback(
    (users: GetPartnerUsersResponsePartnerUser[]) => users.map(
      (user: GetPartnerUsersResponsePartnerUser) => ({
        id: user.partnerUserId,
        label: `${user.name}, ${user.email}`,
      }),
    ),
    [],
  );

  return (
    <EntitySelectorModal
      isOpen={isOpen}
      title={t('addUsersToGroupModal.title')}
      placeholder={t('addUsersToGroupModal.selectUsersPlaceholder')}
      description={t('addUsersToGroupModal.description')}
      onSubmit={handleSubmitEntitySelectorModal}
      request={handleAutocompleteRequest}
      responseParser={handleResponseEntitySelectorModal}
      onClose={onClose}
      isLoading={isUsersSelectorModalLoading}
    />
  );
};

export default memo(UsersSelectorModal);
