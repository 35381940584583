import { memo, useCallback } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  ReactHookFormTextField,
  useTranslations,
  emptyStringValidator,
} from '@uniqkey-frontend/shared-app';
import { useForm } from 'react-hook-form';
import { CreateGroupRequest } from '@uniqkey-backend-partner/api-client';

export interface ICreateGroupModalFormValue extends CreateGroupRequest {
}

interface ICreateGroupModalProps {
  isOpen: boolean;
  isLoading: boolean;
  onSubmit: (params: ICreateGroupModalFormValue) => Promise<void> | void;
  onClose: () => void;
}

const CreateGroupModal = (props: ICreateGroupModalProps) => {
  const {
    isOpen, isLoading, onSubmit, onClose,
  } = props;

  const { t } = useTranslations();

  const {
    handleSubmit,
    formState: { isDirty, errors },
    control,
  } = useForm<ICreateGroupModalFormValue>({
    defaultValues: {
      name: '',
      description: '',
    },
    mode: 'all',
  });

  const handleFormSubmit = useCallback((formValue: ICreateGroupModalFormValue) => {
    onSubmit(formValue);
  }, [onSubmit]);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      clickOutsideToClose={!isLoading}
    >
      <form onSubmit={handleSubmit(handleFormSubmit)} autoComplete="off" noValidate>
        <DialogTitle isLoading={isLoading} onClose={onClose}>
          {t('createGroupModal.title')}
        </DialogTitle>
        <DialogContent>
          <Grid container direction="row" rowSpacing={3} columnSpacing={2}>
            <Grid item xs={12}>
              <ReactHookFormTextField
                control={control}
                name="name"
                fullWidth
                autoFocus
                label={`${t('createGroupModal.name.label')}*`}
                placeholder={t('createGroupModal.name.placeholder')}
                error={!!errors.name}
                helperText={errors.name?.message}
                rules={{
                  required: t('validation.required'),
                  validate: (value) => (
                    emptyStringValidator(value as string) ? t('validation.required') : true
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <ReactHookFormTextField
                control={control}
                name="description"
                fullWidth
                label={t('createGroupModal.description.label')}
                placeholder={t('createGroupModal.description.placeholder')}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container columnSpacing={2} justifyContent="center">
            <Grid item xs={6}>
              <Button fullWidth isLoading={isLoading} disabled={!isDirty} type="submit">
                {t('common.create')}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button fullWidth variant="outlined" disabled={isLoading} onClick={onClose}>
                {t('common.cancel')}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default memo(CreateGroupModal);
