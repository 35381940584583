import { useMemo, ReactNode } from 'react';

import {
  PartnerOrganizationsApi,
  PartnerUsersApi,
  PartnersApi,
  ContactPersonApi,
  LoginApi,
  AuditLogApi,
  UserProfileApi,
  EmployeeAccountsApi,
  PermissionsApi,
  GroupsApi,
  PartnerUserKeysApi,
  PartnerKeysApi,
} from '@uniqkey-backend-partner/api-client';
import { axiosInstance } from '../../axios';
import APIClientsContext from '.';
import config from '../../config';

const PARTNER_API_BASE_URL = config.getPartnerApiUrl();

const APIClientsProviderContext = ({ children }: { children: ReactNode }) => {
  const value = useMemo(() => ({
    axiosInstance,
    partnerOrganizationsAPIClient: new PartnerOrganizationsApi(
      undefined,
      PARTNER_API_BASE_URL,
      axiosInstance,
    ),
    partnerUsersAPIClient: new PartnerUsersApi(
      undefined,
      PARTNER_API_BASE_URL,
      axiosInstance,
    ),
    partnersAPIClient: new PartnersApi(
      undefined,
      PARTNER_API_BASE_URL,
      axiosInstance,
    ),
    contactPersonAPIClient: new ContactPersonApi(
      undefined,
      PARTNER_API_BASE_URL,
      axiosInstance,
    ),
    authAPIClient: new LoginApi(
      undefined,
      PARTNER_API_BASE_URL,
      axiosInstance,
    ),
    auditLogsAPIClient: new AuditLogApi(
      undefined,
      PARTNER_API_BASE_URL,
      axiosInstance,
    ),
    userProfileAPIClient: new UserProfileApi(
      undefined,
      PARTNER_API_BASE_URL,
      axiosInstance,
    ),
    employeeAccountsAPIClient: new EmployeeAccountsApi(
      undefined,
      PARTNER_API_BASE_URL,
      axiosInstance,
    ),
    permissionsAPIClient: new PermissionsApi(
      undefined,
      PARTNER_API_BASE_URL,
      axiosInstance,
    ),
    groupsAPIClient: new GroupsApi(
      undefined,
      PARTNER_API_BASE_URL,
      axiosInstance,
    ),
    partnerUserKeysAPIClient: new PartnerUserKeysApi(
      undefined,
      PARTNER_API_BASE_URL,
      axiosInstance,
    ),
    partnerKeysAPIClient: new PartnerKeysApi(
      undefined,
      PARTNER_API_BASE_URL,
      axiosInstance,
    ),
  }), []);
  return (
    <APIClientsContext.Provider value={value}>
      {children}
    </APIClientsContext.Provider>
  );
};

export default APIClientsProviderContext;
