import { useCallback, useState } from 'react';
import {
  Grid,
  Panel,
  Box,
  Button,
  GroupIcon,
  useTranslations,
  SearchableTextField,
  RenderIf,
  useSnackbar,
  ISearchableTextFieldProps,
} from '@uniqkey-frontend/shared-app';
import { MeResponse, CreateGroupRequest } from '@uniqkey-backend-partner/api-client';
import ACLEnum from '../../enums/ACLEnum';
import { useUser } from '../../contexts/UserContext';
import GroupsTable from '../../components/tables/GroupsTable';
import useGroupsTable from '../../hooks/tables/useGroupsTable';
import useGroupsAPI from '../../hooks/useGroupsAPI';
import CreateGroupModal from './components/CreateGroupModal';

const GroupsPage = () => {
  const { currentUser, userCan } = useUser();
  const { partnerId } = currentUser ?? {} as MeResponse;

  const { showSuccess, showError } = useSnackbar();
  const { t } = useTranslations();

  const [isCreateGroupModalOpen, setIsCreateGroupModalOpen] = useState(false);
  const [isCreateGroupLoading, setIsCreateGroupLoading] = useState(false);

  const {
    selectedGroups,
    searchQuery,
    setSearchQuery,
    resetActivePage,
    refetch,
    ...restTableProps
  } = useGroupsTable({
    noDataMessageKey: 'groupsPage.noData',
  });

  const { createGroup } = useGroupsAPI();

  const handleSearchChange = useCallback<ISearchableTextFieldProps['onChange']>(
    (debouncedValue) => {
      setSearchQuery(debouncedValue);
      resetActivePage();
    },
    [setSearchQuery, resetActivePage],
  );

  const handleCreateGroupModalOpen = useCallback(
    () => setIsCreateGroupModalOpen(true),
    [],
  );

  const handleCreateGroupModalClose = useCallback(
    () => setIsCreateGroupModalOpen(false),
    [],
  );

  const handleCreateGroup = useCallback(async (
    group: CreateGroupRequest,
  ) => {
    try {
      setIsCreateGroupLoading(true);
      await createGroup({ ...group, partnerId });
      showSuccess({
        text: t('createGroupModal.groupCreated'),
      });
      handleCreateGroupModalClose();
      refetch();
    } catch (error) {
      showError({ text: t('common.somethingWentWrong') });
    } finally {
      setIsCreateGroupLoading(false);
    }
  }, [partnerId, createGroup, handleCreateGroupModalClose, refetch, showError, showSuccess, t]);

  return (
    <Grid container flexDirection="column" className="min-height-100-percent">
      <Grid item mb={1}>
        <Panel>
          <Box p={1}>
            <Grid container justifyContent="space-between" alignItems="stretch">
              <Grid item xs container justifyContent="flex-end" flexWrap="nowrap">
                <Grid item>
                  <SearchableTextField
                    value={searchQuery}
                    onChange={handleSearchChange}
                    placeholder={t('common.search')}
                  />
                </Grid>
                <RenderIf condition={userCan(ACLEnum.GroupCreate)}>
                  <Box mr={3} />
                  <Grid item>
                    <Button
                      icon={<GroupIcon />}
                      onClick={handleCreateGroupModalOpen}
                    >
                      {t('groupsPage.createGroupButton')}
                    </Button>
                  </Grid>
                </RenderIf>
              </Grid>
            </Grid>
          </Box>
        </Panel>
      </Grid>
      <Grid item xs>
        <Panel>
          <GroupsTable
            selectedGroups={selectedGroups}
            {...restTableProps}
          />
        </Panel>
      </Grid>
      {isCreateGroupModalOpen && (
        <CreateGroupModal
          isOpen={isCreateGroupModalOpen}
          isLoading={isCreateGroupLoading}
          onSubmit={handleCreateGroup}
          onClose={handleCreateGroupModalClose}
        />
      )}
    </Grid>
  );
};

export default GroupsPage;
