import { memo, useCallback, useState } from 'react';
import {
  WidgetContainer,
  Grid,
  Typography,
  Button,
  useTranslations,
} from '@uniqkey-frontend/shared-app';
import OrganizationsSelectorModal from '../../../../../OrganizationsSelectorModal';

interface IAddOrganizationToGroupWidgetNoDataProps {
  groupId: string;
  refetch: () => void;
}

const AddOrganizationToGroupWidgetNoData = (props: IAddOrganizationToGroupWidgetNoDataProps) => {
  const { groupId, refetch } = props;
  const { t } = useTranslations();

  const [isOrganizationsSelectorModalOpen, setIsOrganizationsSelectorModalOpen] = useState(false);
  const handleOrganizationsSelectorModalOpen = useCallback(
    () => setIsOrganizationsSelectorModalOpen(true),
    [],
  );
  const handleOrganizationsSelectorModalClose = useCallback(
    () => setIsOrganizationsSelectorModalOpen(false),
    [],
  );

  return (
    <>
      <WidgetContainer
        container
        py={3}
        withShadow
        minHeight={176}
        flexDirection="column"
        justifyContent="space-between"
      >
        <Grid item>
          <Typography variant="h6">{t('addOrganizationToGroupWidget.title')}</Typography>
        </Grid>
        <Grid item container justifyContent="center">
          <Typography>{t('addOrganizationToGroupWidget.noData')}</Typography>
        </Grid>
        <Grid item container>
          <Button variant="outlined" onClick={handleOrganizationsSelectorModalOpen}>
            {t('addOrganizationToGroupWidget.addOrganization')}
          </Button>
        </Grid>
      </WidgetContainer>
      {isOrganizationsSelectorModalOpen && (
        <OrganizationsSelectorModal
          isOpen={isOrganizationsSelectorModalOpen}
          onClose={handleOrganizationsSelectorModalClose}
          groupId={groupId}
          refetch={refetch}
        />
      )}
    </>
  );
};

export default memo(AddOrganizationToGroupWidgetNoData);
