import {
  Dispatch, SetStateAction, useCallback, useState,
} from 'react';
import { upperFirst } from 'lodash';
import {
  useSnackbar,
  useTable,
  useTableFetch,
  useTranslations,
  useTableLocalization,
  useTablePreserverActivePageValidator,
  useTablePreserverDecoder,
  useTablePreserverEncoder,
  usePubSub,
  buildTablePreserverDecoderConfigItem,
  TablePreserverCommonInitialTableValues,
  IUseTableReturn,
  IUseTableResetTableMethodsReturn,
  IUseTableFetchReturn,
  IUseTableLocalizationReturn,
  TUseTablePreserverDecoderConfigs,
  TablePreserverDecoderConfigItemTypeEnum,
  TSetSearchParams,
} from '@uniqkey-frontend/shared-app';
import {
  GroupsResponseGroup,
  GroupOrderProperty,
} from '@uniqkey-backend-partner/api-client';
import { useSearchParams } from 'react-router-dom';
import PubSubEventEnum from '../../../enums/PubSubEventEnum';
import { IGetGroupsParams, TGetGroupsParams } from '../../useGroupsAPI/interfaces';
import { IGroupsTableRow } from '../../../components/tables/GroupsTable';
import useGroupsAPI from '../../useGroupsAPI';

export interface IUseGroupsTableProps {
  noDataMessageKey: string;
}

export interface IUseGroupsTableReturn extends
  Omit<IUseTableReturn<IGroupsTableRow>, 'selectedRows' | 'resetTableMethods'>,
  Pick<IUseTableResetTableMethodsReturn, 'resetActivePage' | 'resetSelectedRows'>,
  Omit<IUseTableFetchReturn<GroupsResponseGroup>, 'data'>
{
  groups: IUseTableFetchReturn<GroupsResponseGroup>['data'];
  selectedGroups: IUseTableReturn<IGroupsTableRow>['selectedRows'];
  localization: IUseTableLocalizationReturn['localization'];
  searchQuery: IGetGroupsParams['searchQuery'];
  setSearchQuery: Dispatch<SetStateAction<IGetGroupsParams['searchQuery']>>;
}

interface ITablePreservationConfigs extends
  Omit<IGetGroupsParams,
    'page' | 'pageLength' | 'partnerId' | 'orderPropertyName' | 'isDescending' | 'cancelToken'
    | 'roleFilter'
  >,
  Pick<IUseTableReturn<IGroupsTableRow>, 'columnOrderBy' | 'columnOrderDirection'>
{
  activePage: IGetGroupsParams['page'];
  perPage: IGetGroupsParams['pageLength'];
}

const PRESERVATION_CONFIGS: TUseTablePreserverDecoderConfigs<ITablePreservationConfigs> = {
  activePage: true,
  perPage: true,
  searchQuery: true,
  columnOrderBy: buildTablePreserverDecoderConfigItem(
    TablePreserverDecoderConfigItemTypeEnum.ENUM,
    { enumToCheck: GroupOrderProperty },
  ),
  columnOrderDirection: true,
};

const useGroupsTable = (
  props: IUseGroupsTableProps,
): IUseGroupsTableReturn => {
  const {
    noDataMessageKey,
  } = props;

  const { t } = useTranslations();
  const { showError } = useSnackbar();
  const { getGroups } = useGroupsAPI();
  const [searchParams, setSearchParams] = useSearchParams();

  const handleOnRequestError = useCallback(() => {
    showError({ text: t('common.somethingWentWrong') });
  }, [showError, t]);

  const {
    activePage: initialActivePage,
    perPage: initialPerPage,
    searchQuery: initialSearchQuery,
    columnOrderBy: initialColumnOrderBy,
    columnOrderDirection: initialColumnOrderDirection,
  } = useTablePreserverDecoder<ITablePreservationConfigs>(searchParams, PRESERVATION_CONFIGS);

  const [searchQuery, setSearchQuery] = useState<
    IGetGroupsParams['searchQuery']
  >(() => initialSearchQuery);

  const {
    activePage,
    perPage,
    columnOrderBy,
    columnOrderDirection,
    selectedRows: selectedGroups,
    resetTableMethods,
    ...restTableProps
  } = useTable<IGroupsTableRow>({
    initialActivePage,
    initialPerPage,
    initialColumnOrderBy,
    initialColumnOrderDirection,
  });

  const {
    resetTable,
    resetActivePage,
    resetSelectedRows,
  } = resetTableMethods;

  const handleTableReset = useCallback(() => {
    resetTable();
    setSearchQuery(TablePreserverCommonInitialTableValues.searchQuery);
  }, [resetTable]);
  usePubSub(PubSubEventEnum.RESET_TABLE, handleTableReset);

  const {
    data: groups, isLoading, isFetchedAfterMount, total, refetch,
  } = useTableFetch<
    IGetGroupsParams,
    TGetGroupsParams,
    GroupsResponseGroup
  >({
    request: getGroups,
    params: {
      page: activePage,
      pageLength: perPage,
      searchQuery,
      orderPropertyName: GroupOrderProperty[
        upperFirst(columnOrderBy) as keyof typeof GroupOrderProperty
      ],
      isDescending: columnOrderDirection ? columnOrderDirection === 'desc' : undefined,
    },
    onRequestError: handleOnRequestError,
    autoRefetch: true,
  });

  const { localization } = useTableLocalization({
    searchQuery,
    noDataMessageKey,
    isLoading,
  });

  useTablePreserverActivePageValidator({
    activePage, resetActivePage, perPage, total, isFetchedAfterMount,
  });

  useTablePreserverEncoder<ITablePreservationConfigs>(setSearchParams as TSetSearchParams, {
    activePage,
    perPage,
    searchQuery,
    columnOrderBy,
    columnOrderDirection,
  });

  return {
    groups,
    isLoading,
    total,
    refetch,
    activePage,
    perPage,
    columnOrderBy,
    columnOrderDirection,
    selectedGroups,
    localization,
    searchQuery,
    setSearchQuery,
    resetActivePage,
    resetSelectedRows,
    ...restTableProps,
  };
};

export default useGroupsTable;
