import { memo, useCallback, useState } from 'react';
import {
  PanelContent,
  Grid,
  Divider,
  Button,
  useTranslations,
  OrganizationIcon,
  useSnackbar,
  useMapKeyValueExtractor,
  Tooltip,
  ActionButton,
  DeleteIcon,
  PromptModal,
} from '@uniqkey-frontend/shared-app';
import useGroupOrganizationsTable from '../../../../hooks/tables/useGroupOrganizationsTable';
import GroupOrganizationsTable, {
  IGroupOrganizationsTableRow,
} from '../../../../components/tables/GroupOrganizationsTable';
import { useRemoveOrganizationsFromGroup } from '../../../../hooks/reactQuery';
import { logException } from '../../../../services/sentryService';
import OrganizationsSelectorModal from '../OrganizationsSelectorModal';

interface IGroupOrganizationsTabProps {
  groupId: string;
}

const GroupOrganizationsTab = (props: IGroupOrganizationsTabProps) => {
  const { groupId } = props;
  const { t } = useTranslations();
  const { showError, showSuccess } = useSnackbar();

  const [isOrganizationsSelectorModalOpen, setIsOrganizationsSelectorModalOpen] = useState(false);
  const [
    isRemoveOrganizationsFromGroupModalOpen,
    setIsRemoveOrganizationsFromGroupModalOpen,
  ] = useState(false);

  const handleOrganizationsSelectorModalOpen = useCallback(
    () => setIsOrganizationsSelectorModalOpen(true),
    [],
  );
  const handleOrganizationsSelectorModalClose = useCallback(
    () => setIsOrganizationsSelectorModalOpen(false),
    [],
  );

  const handleRemoveOrganizationsFromGroupModalOpen = useCallback(
    () => setIsRemoveOrganizationsFromGroupModalOpen(true),
    [],
  );

  const handleRemoveOrganizationsFromGroupModalClose = useCallback(
    () => setIsRemoveOrganizationsFromGroupModalOpen(false),
    [],
  );

  const {
    selectedGroupOrganizations,
    resetSelectedRows,
    resetActivePage,
    refetch,
    ...restTableProps
  } = useGroupOrganizationsTable({
    persistentFilters: { groupId },
    noDataMessageKey: 'table.noData.default',
  });

  const {
    mutate: mutateRemoveOrganizationsFromGroup,
    isLoading: isRemoveOrganizationsFromGroupLoading,
  } = useRemoveOrganizationsFromGroup();

  const {
    values: selectedOrganizationsAsObjects, keys: selectedOrganizationsIds,
  } = useMapKeyValueExtractor<IGroupOrganizationsTableRow>(selectedGroupOrganizations);

  const handleRemoveOrganizationsFromGroup = useCallback(async () => {
    mutateRemoveOrganizationsFromGroup(
      { groupId, organizationIds: selectedOrganizationsIds },
      {
        onSuccess: ({ failCount, successCount }) => {
          if (successCount) {
            showSuccess({
              text: t(
                'removeOrganizationsFromGroupModal.successMessage',
                { count: successCount },
              ),
            });
          }
          if (failCount) {
            showError({
              text: t(
                'removeOrganizationsFromGroupModal.errorMessage',
                { count: failCount },
              ),
            });
          }
          handleRemoveOrganizationsFromGroupModalClose();
          resetSelectedRows();
          resetActivePage();
        },
        onError: (e) => {
          showError({ text: t('common.somethingWentWrong') });
          logException(
            e,
            { message: 'GroupOrganizationsTab/handleRemoveOrganizationsFromGroup exception' },
          );
        },
        onSettled: () => {
          refetch();
        },
      },
    );
  }, [
    mutateRemoveOrganizationsFromGroup,
    handleRemoveOrganizationsFromGroupModalClose,
    groupId,
    selectedOrganizationsIds,
    resetSelectedRows,
    resetActivePage,
    refetch,
    showError,
    showSuccess,
    t,
  ]);

  return (
    <PanelContent p={0}>
      <Grid item container justifyContent="flex-end" flexWrap="nowrap" p={1}>
        <Grid item xs={4} container flexWrap="nowrap" spacing={1}>
          {!!selectedGroupOrganizations.size && (
          <>
            <Grid item my={0.5}>
              <Divider orientation="vertical" />
            </Grid>
            <Grid item alignSelf="center">
              <Tooltip title={t('common.remove')}>
                <ActionButton
                  width={40}
                  height={40}
                  onClick={handleRemoveOrganizationsFromGroupModalOpen}
                >
                  <DeleteIcon />
                </ActionButton>
              </Tooltip>
            </Grid>
            <Grid item my={0.5}>
              <Divider orientation="vertical" />
            </Grid>
          </>
          )}
        </Grid>
        <Grid item xs={8} container justifyContent="flex-end" flexWrap="nowrap">
          <Button
            icon={<OrganizationIcon />}
            onClick={handleOrganizationsSelectorModalOpen}
          >
            {t('groupPage.organizationsTab.addOrganizationButton')}
          </Button>
        </Grid>
      </Grid>
      <Divider />
      <GroupOrganizationsTable
        groupId={groupId}
        selectedGroupOrganizations={selectedGroupOrganizations}
        {...restTableProps}
      />
      {isOrganizationsSelectorModalOpen && (
        <OrganizationsSelectorModal
          isOpen={isOrganizationsSelectorModalOpen}
          onClose={handleOrganizationsSelectorModalClose}
          groupId={groupId}
          refetch={refetch}
        />
      )}
      {isRemoveOrganizationsFromGroupModalOpen && (
        <PromptModal
          open={isRemoveOrganizationsFromGroupModalOpen}
          onClose={handleRemoveOrganizationsFromGroupModalClose}
          onSubmit={handleRemoveOrganizationsFromGroup}
          title={t(
            'removeOrganizationsFromGroupModal.title',
            { count: selectedGroupOrganizations.size },
          )}
          description={t('removeOrganizationsFromGroupModal.description')}
          approvalButtonText="common.remove"
          list={selectedOrganizationsAsObjects}
          renderField="name"
          renderKey="organizationId"
          isLoading={isRemoveOrganizationsFromGroupLoading}
        />
      )}
    </PanelContent>
  );
};

export default memo(GroupOrganizationsTab);
