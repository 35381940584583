import { useCallback, memo, SyntheticEvent } from 'react';
import {
  Panel, PanelHeader, Tabs, Tab, useTranslations, RenderIf,
} from '@uniqkey-frontend/shared-app';
import { useNavigate, useOutletContext } from 'react-router-dom';
import PageRouteEnum from '../../../../enums/PageRouteEnum';
import { publishTablePreservationReset } from '../../../../helpers/tablePreservation/reset';
import { useGetGroupById } from '../../../../hooks/reactQuery';
import GroupDetailsTab from '../GroupDetailsTab';
import GroupUsersTab from '../GroupUsersTab';
import GroupOrganizationsTab from '../GroupOrganizationsTab';

export enum GroupModuleTabEnum {
  Details = 'details',
  Users = 'users',
  Organizations = 'organizations'
}
interface IGroupModuleProps {
  activeTab: GroupModuleTabEnum;
}
interface IGroupModuleContext {
  groupId: string;
}

const GroupModule = (props: IGroupModuleProps) => {
  const { activeTab } = props;
  const { t } = useTranslations();
  const { groupId } = useOutletContext<IGroupModuleContext>();
  const navigate = useNavigate();

  const { isLoading } = useGetGroupById(
    { groupId },
  );

  const handleTabClick = useCallback((
    event: SyntheticEvent,
    nextActiveTab: GroupModuleTabEnum,
  ) => {
    const nextPath = `${PageRouteEnum.Groups}/${groupId}/${nextActiveTab}`;
    if (activeTab !== nextActiveTab) {
      navigate(nextPath);
      return;
    }
    navigate(nextPath, { replace: true });
    publishTablePreservationReset();
  }, [groupId, activeTab, navigate]);

  const hidePanelHeaderBottomDivider = activeTab === GroupModuleTabEnum.Details;

  return (
    <Panel className="height-100-percent flex-column">
      <PanelHeader px={3} hideBottomDivider={hidePanelHeaderBottomDivider}>
        <Tabs value={activeTab} isLoading={isLoading}>
          <Tab
            label={t('groupPage.tabs.groupDetails')}
            value={GroupModuleTabEnum.Details}
            onClick={handleTabClick}
          />
          <Tab
            label={t('groupPage.tabs.groupUsers')}
            value={GroupModuleTabEnum.Users}
            onClick={handleTabClick}
          />
          <Tab
            label={t('groupPage.tabs.groupOrganizations')}
            value={GroupModuleTabEnum.Organizations}
            onClick={handleTabClick}
          />
        </Tabs>
      </PanelHeader>
      <RenderIf condition={activeTab === GroupModuleTabEnum.Details}>
        <GroupDetailsTab groupId={groupId} />
      </RenderIf>
      <RenderIf condition={activeTab === GroupModuleTabEnum.Users}>
        <GroupUsersTab groupId={groupId} />
      </RenderIf>
      <RenderIf condition={activeTab === GroupModuleTabEnum.Organizations}>
        <GroupOrganizationsTab groupId={groupId} />
      </RenderIf>
    </Panel>
  );
};

export default memo(GroupModule);
