import { memo, useCallback, useState } from 'react';
import {
  WidgetContainer,
  Grid,
  Typography,
  Button,
  useTranslations,
} from '@uniqkey-frontend/shared-app';
import UsersSelectorModal from '../../../../../UsersSelectorModal';

interface IAddUserToGroupWidgetNoDataProps {
  groupId: string;
  refetch: () => void;
}

const AddUserToGroupWidgetNoData = (props: IAddUserToGroupWidgetNoDataProps) => {
  const { groupId, refetch } = props;
  const { t } = useTranslations();

  const [isUsersSelectorModalOpen, setIsUsersSelectorModalOpen] = useState(false);
  const handleUsersSelectorModalOpen = useCallback(
    () => setIsUsersSelectorModalOpen(true),
    [],
  );
  const handleUsersSelectorModalClose = useCallback(
    () => setIsUsersSelectorModalOpen(false),
    [],
  );

  return (
    <>
      <WidgetContainer
        container
        py={3}
        withShadow
        minHeight={176}
        flexDirection="column"
        justifyContent="space-between"
      >
        <Grid item>
          <Typography variant="h6">{t('addUserToGroupWidget.title')}</Typography>
        </Grid>
        <Grid item container justifyContent="center">
          <Typography>{t('addUserToGroupWidget.noData')}</Typography>
        </Grid>
        <Grid item container>
          <Button variant="outlined" onClick={handleUsersSelectorModalOpen}>
            {t('addUserToGroupWidget.addUser')}
          </Button>
        </Grid>
      </WidgetContainer>
      {isUsersSelectorModalOpen && (
        <UsersSelectorModal
          isOpen={isUsersSelectorModalOpen}
          onClose={handleUsersSelectorModalClose}
          groupId={groupId}
          refetch={refetch}
        />
      )}
    </>
  );
};

export default memo(AddUserToGroupWidgetNoData);
