import { memo } from 'react';
import {
  Grid,
  ScrollablePanelContent,
} from '@uniqkey-frontend/shared-app';
import GroupDetailsWidget from './components/GroupDetailsWidget';
import AddUserToGroupWidget from './components/AddUserToGroupWidget';
import AddOrganizationToGroupWidget from './components/AddOrganizationToGroupWidget';

interface IGroupDetailsTabProps {
  groupId: string;
}

const GroupDetailsTab = (props: IGroupDetailsTabProps) => {
  const { groupId } = props;

  return (
    <ScrollablePanelContent p={3} pt={1}>
      <Grid container spacing={2}>
        <Grid container item xs={6} rowGap={3}>
          <GroupDetailsWidget groupId={groupId} />
          <AddUserToGroupWidget groupId={groupId} />
        </Grid>
        <Grid item xs={6}>
          <AddOrganizationToGroupWidget groupId={groupId} />
        </Grid>
      </Grid>
    </ScrollablePanelContent>
  );
};

export default memo(GroupDetailsTab);
