import { useMemo, memo, useCallback } from 'react';
import {
  AG2,
  AR1,
  AO4,
  S4,
  S5,
  AO3,
  Table,
  useTranslations,
  IUseTableReturn,
  IUseTableFetchReturn,
  IUseTableLocalizationReturn,
  TypographyWithTooltip,
  IMaterialTableOptions,
  Typography,
  getTimeFromNow,
  SecurityScoreIcon,
  IMaterialTableProps,
} from '@uniqkey-frontend/shared-app';
import {
  EmployeeAccountStatus,
  EmployeeAccountType,
  GetEmployeeAccountsResponseModel,
} from '@uniqkey-backend-partner/api-client';
import { useNavigate } from 'react-router-dom';
import PageRouteEnum from '../../../enums/PageRouteEnum';
import {
  EMPLOYEE_ACCOUNT_TYPES_TRANSLATION_KEYS,
  SCIM_TRANSLATION_KEYS,
  EMPLOYEE_ACCOUNT_STATUS_TRANSLATION_KEYS,
} from '../../../constants';

export interface IOrganizationEmployeesTableRow extends GetEmployeeAccountsResponseModel {
  lastActivity: string | null;
}

interface IOrganizationEmployeesTableProps extends
  Omit<IUseTableReturn<IOrganizationEmployeesTableRow>, 'selectedRows' | 'resetTableMethods'>,
  Omit<IUseTableFetchReturn<GetEmployeeAccountsResponseModel>, 'data' | 'refetch'>
{
  employees: IUseTableFetchReturn<GetEmployeeAccountsResponseModel>['data'];
  selectedEmployees: IUseTableReturn<IOrganizationEmployeesTableRow>['selectedRows'];
  localization: IUseTableLocalizationReturn['localization'];
  organizationId: string;
  partnerId?: string;
  groupId?: string;
}

const TABLE_OPTIONS: IMaterialTableOptions<IOrganizationEmployeesTableRow> = {
  selection: false,
};

const STATUS_COLORS = {
  [EmployeeAccountStatus.Active]: AG2,
  [EmployeeAccountStatus.Invited]: S5,
  [EmployeeAccountStatus.Archived]: AR1,
  [EmployeeAccountStatus.Unprocessed]: S4,
  [EmployeeAccountStatus.Staged]: S5,
  [EmployeeAccountStatus.MigrationInvited]: S5,
  [EmployeeAccountStatus.Migrated]: S5,
  [EmployeeAccountStatus.ExistingUnprocessed]: S4,
};
const SHOW_ROLE_COLUMN = {
  [EmployeeAccountStatus.Active]: true,
  [EmployeeAccountStatus.Invited]: true,
  [EmployeeAccountStatus.Archived]: true,
  [EmployeeAccountStatus.Unprocessed]: false,
  [EmployeeAccountStatus.Staged]: true,
  [EmployeeAccountStatus.MigrationInvited]: true,
  [EmployeeAccountStatus.Migrated]: true,
  [EmployeeAccountStatus.ExistingUnprocessed]: false,
};
const SHOW_SECURITY_SCORE_COLUMN = {
  [EmployeeAccountStatus.Active]: true,
  [EmployeeAccountStatus.Invited]: false,
  [EmployeeAccountStatus.Archived]: false,
  [EmployeeAccountStatus.Unprocessed]: false,
  [EmployeeAccountStatus.Staged]: false,
  [EmployeeAccountStatus.MigrationInvited]: true,
  [EmployeeAccountStatus.Migrated]: true,
  [EmployeeAccountStatus.ExistingUnprocessed]: false,
};
const SCIM_COLORS = {
  true: AO4,
  false: S5,
};

const OrganizationEmployeesTable = (props: IOrganizationEmployeesTableProps) => {
  const {
    employees: preEmployees,
    isLoading,
    total,
    localization,
    organizationId,
    partnerId,
    groupId,
    activePage,
    setActivePage,
    perPage,
    onPerPageChange,
    columnOrderBy,
    onColumnOrderByChange,
    columnOrderDirection,
    onColumnOrderDirectionChange,
    selectedEmployees,
    onRowSelectionChange,
    createColumns,
  } = props;

  const { t, currentLanguage } = useTranslations();
  const navigate = useNavigate();

  const employees = useMemo(() => preEmployees.map((employee) => ({
    ...employee,
    lastActivity: getTimeFromNow({
      date: employee.activityAt,
      locale: currentLanguage,
    }),
  })), [preEmployees, currentLanguage]);

  const columns = useMemo(() => createColumns([
    {
      title: t('organizationPage.employeesTab.status'),
      field: 'employeeAccountStatus',
      render: (rowData) => (
        <Typography variant="body2" color={STATUS_COLORS[rowData.employeeAccountStatus]}>
          {t(EMPLOYEE_ACCOUNT_STATUS_TRANSLATION_KEYS[rowData.employeeAccountStatus])}
        </Typography>
      ),
      width: '15%',
    },
    {
      title: t('organizationPage.employeesTab.email'),
      field: 'email',
      render: (rowData) => (
        <TypographyWithTooltip variant="body2">
          {rowData.email}
        </TypographyWithTooltip>
      ),
      width: '20%',
    },
    {
      title: t('organizationPage.employeesTab.name'),
      field: 'name',
      render: (rowData) => (
        <TypographyWithTooltip variant="body2">
          {rowData.name}
        </TypographyWithTooltip>
      ),
      width: '15%',
    },
    {
      title: t('organizationPage.employeesTab.role'),
      field: 'employeeAccountType',
      render: (rowData) => SHOW_ROLE_COLUMN[rowData.employeeAccountStatus] && (
        <TypographyWithTooltip
          variant="body2"
          color={rowData.employeeAccountType === EmployeeAccountType.KeylessAdmin ? AO3 : S5}
        >
          {t(EMPLOYEE_ACCOUNT_TYPES_TRANSLATION_KEYS[rowData.employeeAccountType])}
        </TypographyWithTooltip>
      ),
      width: '10%',
    },
    {
      title: t('organizationPage.employeesTab.securityScore'),
      field: 'securityScore',
      render: (rowData) => SHOW_SECURITY_SCORE_COLUMN[rowData.employeeAccountStatus] && (
        <SecurityScoreIcon percentage={rowData.securityScore} />
      ),
      width: '15%',
      sorting: false,
    },
    {
      title: t(SCIM_TRANSLATION_KEYS.true),
      field: 'isByScim',
      render: (rowData) => (
        <Typography
          variant="body2"
          color={SCIM_COLORS[rowData.isByScim.toString() as keyof typeof SCIM_COLORS]}
        >
          {t(SCIM_TRANSLATION_KEYS[
            rowData.isByScim.toString() as keyof typeof SCIM_TRANSLATION_KEYS
          ])}
        </Typography>
      ),
      width: '10%',
    },
    {
      title: t('organizationPage.employeesTab.lastActivity'),
      field: 'activityAt',
      render: (rowData) => (
        <TypographyWithTooltip variant="body2">
          {rowData.lastActivity}
        </TypographyWithTooltip>
      ),
      width: '15%',
    },
  ]), [createColumns, t]);

  const handleEmployeeRowClick = useCallback<
    NonNullable<IMaterialTableProps<IOrganizationEmployeesTableRow>['onRowClick']>
  >((event, employee) => {
    const { employeeAccountStatus, employeeAccountId } = employee!;
    if (employeeAccountStatus === EmployeeAccountStatus.Unprocessed
        || employeeAccountStatus === EmployeeAccountStatus.ExistingUnprocessed) {
      return;
    }
    if (partnerId) {
      navigate(
        `${
          PageRouteEnum.Partners
        }/${partnerId}/${organizationId}/${employeeAccountId}`,
      );
      return;
    }
    if (groupId) {
      navigate(
        `${
          PageRouteEnum.Groups
        }/${groupId}/${organizationId}/${employeeAccountId}`,
      );
      return;
    }
    navigate(`${PageRouteEnum.Employee}/${organizationId}/${employeeAccountId}`);
  }, [partnerId, groupId, navigate, organizationId]);

  return (
    <Table
      t={t}
      totalItems={total}
      columns={columns}
      data={employees}
      isLoading={isLoading}
      options={TABLE_OPTIONS}
      activePage={activePage}
      onActivePageChange={setActivePage}
      perPage={perPage}
      onPerPageChange={onPerPageChange}
      columnOrderBy={columnOrderBy}
      onColumnOrderByChange={onColumnOrderByChange}
      columnOrderDirection={columnOrderDirection}
      onColumnOrderDirectionChange={onColumnOrderDirectionChange}
      selectedRows={selectedEmployees}
      onSelectionChange={onRowSelectionChange}
      localization={localization}
      onRowClick={handleEmployeeRowClick}
      rowSelectByKey="employeeAccountId"
      rowHeight={51}
    />
  );
};

export default memo(OrganizationEmployeesTable);
