// TODO: NEXTGEN-7334: remove 'eslint-disable-next-line max-classes-per-file' below
// eslint-disable-next-line max-classes-per-file
import {
  decryptSymmetric,
  generateAsymmetricKeys,
  encryptSymmetric,
  derivePasswordKey,
  Either,
  BaseEitherError,
} from '@uniqkey-frontend/shared-app';
import {
  PartnerUserKeysApi,
  GetPartnerUserKeyResponse,
  GetUsersPublicKeysRequest,
  GetUsersPublicKeysResponse,
  GetUsersPublicKeysResponseData,
} from '@uniqkey-backend-partner/api-client';
import config from '../../../config';
import { axiosInstance } from '../../../axios';
import { logException } from '../../sentryService';
import { dataExtractor } from '../../../helpers/apiClients';

const partnerUserKeysClient = new PartnerUserKeysApi(
  undefined,
  config.getPartnerApiUrl(),
  axiosInstance,
);

export interface IPartnerUserKeys {
  privateKey: string;
  publicKey: string;
}

const createPartnerUserKeys = async (
  derivedMasterPassword: string,
): Promise<IPartnerUserKeys | null> => {
  try {
    const {
      privateKey: generatedPrivateKey,
      publicKey: generatedPublicKey,
    } = await generateAsymmetricKeys();

    const encryptedPrivateKey = encryptSymmetric({
      string: generatedPrivateKey,
      key: derivedMasterPassword,
    });

    await partnerUserKeysClient.apiV1PartnerUserKeysPut({
      privateKey: encryptedPrivateKey,
      publicKey: generatedPublicKey,
    });

    return {
      privateKey: generatedPrivateKey,
      publicKey: generatedPublicKey,
    };
  } catch (e) {
    logException(e, {
      message: 'keysManager/createPartnerUserKeys failed to create partner user keys',
    });
    return null;
  }
};

export class KeysManagerFetchPartnerUserKeysError extends BaseEitherError {}

export class KeysManagerCannotDecryptPartnerUserKeysError extends BaseEitherError {}

type TFetchPartnerUserKeysErrors = KeysManagerFetchPartnerUserKeysError
  | KeysManagerCannotDecryptPartnerUserKeysError;

// TODO: NEXTGEN-7334: move error classes to /operations/errors.ts
// TODO: NEXTGEN-7334: for 'keysManager' - add methods getPartnerUserKeysClient, etc.

export const fetchPartnerUserKeys = async (
  derivedMasterPassword: string,
): Promise<Either<IPartnerUserKeys, TFetchPartnerUserKeysErrors>> => {
  // TODO: NEXTGEN-7334: intermediate modifications
  let privateKey;
  let publicKey;
  try {
    const resp = await partnerUserKeysClient
      .apiV1PartnerUserKeysGet()
      .then(dataExtractor<GetPartnerUserKeyResponse>());
    privateKey = resp.privateKey;
    publicKey = resp.publicKey;
  } catch (e) {
    logException(e, {
      message: 'keysManager/fetchPartnerUserKeys fetch error',
    });
    return new KeysManagerFetchPartnerUserKeysError();
  }

  try {
    const decryptedPrivateKey = decryptSymmetric({
      cipher: privateKey,
      key: derivedMasterPassword,
    });

    if (!decryptedPrivateKey) {
      return new KeysManagerCannotDecryptPartnerUserKeysError();
    }

    return {
      privateKey: decryptedPrivateKey,
      publicKey,
    };
  } catch (e) {
    logException(e, {
      message: 'keysManager/fetchPartnerUserKeys decrypt error',
    });
    return new KeysManagerCannotDecryptPartnerUserKeysError();
  }
};

export const setupPartnerUserKeys = async (
  userId: string,
  password: string,
) : Promise<IPartnerUserKeys | null> => {
  const derivedMasterPassword = await derivePasswordKey(password, userId);

  const fetchedPartnerUserKeysResult = await fetchPartnerUserKeys(derivedMasterPassword);

  if (fetchedPartnerUserKeysResult instanceof BaseEitherError) {
    return createPartnerUserKeys(derivedMasterPassword);
  }

  return fetchedPartnerUserKeysResult;
};

export const fetchPartnerUsersPublicKeys = async (
  publicUserIds: GetUsersPublicKeysRequest,
): Promise<GetUsersPublicKeysResponseData[]> => {
  try {
    const {
      data,
    } = await partnerUserKeysClient
      .apiV1PartnerUserKeysGetUsersPublicKeysPost(publicUserIds)
      .then(dataExtractor<GetUsersPublicKeysResponse>());

    return data;
  } catch (e) {
    logException(e, {
      message: 'keysManager/fetchPartnerUsersPublicKeys failed to fetch partner user keys',
    });
    throw e;
  }
};
