import { memo, useCallback, useState } from 'react';
import {
  ListItem,
  Grid,
  S5,
  IconButton,
  Divider,
  CloseIcon,
  B1,
  PromptModal,
  useTranslations,
  useSnackbar,
  TypographyWithTooltip,
} from '@uniqkey-frontend/shared-app';
import { GroupUsersResponseModel } from '@uniqkey-backend-partner/api-client';
import { logException } from '../../../../../../../../services/sentryService';
import { useRemoveUsersFromGroup } from '../../../../../../../../hooks/reactQuery';

interface IAddUserToGroupWidgetListItemProps {
  user: GroupUsersResponseModel;
  groupId: string;
  refetch: () => void;
}

const ICON_BUTTON_SX = { padding: 0 };

const AddUserToGroupWidgetListItem = (props: IAddUserToGroupWidgetListItemProps) => {
  const { user, groupId, refetch } = props;
  const { name, partnerUserId } = user;

  const { t } = useTranslations();
  const { showError, showSuccess } = useSnackbar();
  const {
    mutate: mutateRemoveUserFromGroup,
    isLoading: isRemoveUserFromGroupLoading,
  } = useRemoveUsersFromGroup();

  const [isRemoveUserFromGroupModalOpen, setIsRemoveUserFromGroupModalOpen] = useState(false);
  const handleRemoveUserFromGroupModalOpen = useCallback(
    () => setIsRemoveUserFromGroupModalOpen(true),
    [],
  );
  const handleRemoveUserFromGroupModalClose = useCallback(
    () => setIsRemoveUserFromGroupModalOpen(false),
    [],
  );

  const handleRemoveUserFromGroup = useCallback(async () => {
    mutateRemoveUserFromGroup(
      { groupId, partnerUserIds: [partnerUserId] },
      {
        onSuccess: () => {
          showSuccess({ text: t('removeUserFromGroupModal.successMessage') });
          handleRemoveUserFromGroupModalClose();
        },
        onError: (e) => {
          showError({ text: t('removeUserFromGroupModal.errorMessage') });
          logException(e, { message: 'AddUserToGroupWidget/handleRemoveUserFromGroup exception' });
        },
        onSettled: () => {
          refetch();
        },
      },
    );
  }, [
    mutateRemoveUserFromGroup,
    handleRemoveUserFromGroupModalClose,
    groupId,
    partnerUserId,
    refetch,
    showError,
    showSuccess,
    t,
  ]);

  return (
    <>
      <ListItem>
        <Grid item container>
          <Grid item container justifyContent="space-between" wrap="nowrap" mt={1}>
            <TypographyWithTooltip variant="body2" color={S5}>
              {name}
            </TypographyWithTooltip>
            <IconButton
              disabled={isRemoveUserFromGroupLoading}
              onClick={handleRemoveUserFromGroupModalOpen}
              sx={ICON_BUTTON_SX}
            >
              <CloseIcon color={B1} width={16} height={16} />
            </IconButton>
          </Grid>
          <Grid item xs={12} mt={1}>
            <Divider />
          </Grid>
        </Grid>
      </ListItem>
      {isRemoveUserFromGroupModalOpen && (
      <PromptModal
        open={isRemoveUserFromGroupModalOpen}
        onClose={handleRemoveUserFromGroupModalClose}
        onSubmit={handleRemoveUserFromGroup}
        title={t('removeUserFromGroupModal.title')}
        description={t('removeUserFromGroupModal.description', { name })}
        approvalButtonText="common.remove"
        isLoading={isRemoveUserFromGroupLoading}
      />
      )}
    </>
  );
};

export default memo(AddUserToGroupWidgetListItem);
