/* eslint-disable max-len */
enum PageRouteEnum {
  Login = '/login',
  SetupMasterPassword = '/setup-password',
  SetupTwoFA = '/setup-2fa',
  LoginTwoFA = '/login-2fa',
  SetupPermanentPassword = '/setup-password',
  Partners = '/partners',
  Partner = '/partners/:partnerId/*',
  PartnerOrganization = '/partners/:partnerId/:organizationId/*',
  PartnersDeep = '/partners/deep',
  PartnerDeep = '/partners/deep/:partnerId1/:partnerId2/:partnerId3/*',
  Organizations = '/organizations',
  Organization = '/organizations/:organizationId/*',
  Groups = '/groups',
  Group = '/groups/:groupId/*',
  GroupOrganization = '/groups/:groupId/:organizationId/*',
  GroupOrganizationEmployee = '/groups/:groupId/:organizationId/:employeeAccountId/*',
  Employee = '/employee',
  OrganizationEmployee = '/employee/:organizationId/:employeeAccountId/*',
  PartnerOrganizationEmployee = '/partners/:partnerId/:organizationId/:employeeAccountId/*',
  User = '/user',
  PartnerPartnerUser = '/user/:partnerId1/:partnerId2/:partnerId3/:partnerUserId/*',
  PartnerUsers = '/partner-users',
  PartnerUser = '/partner-users/:partnerUserId/*',
  Audit = '/audit',
  Profile = '/profile',
  Roles = '/roles',
  Role = '/roles/:role',
}

export default PageRouteEnum;
